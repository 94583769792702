import React from 'react'
import HeroSec from '../sections/Aboutpages/HeroSec'
import AboutSewction from '../sections/Aboutpages/AboutSewction'

function About() {
  return (
   <div className="">
       {/* <HeroSec/> */}
       <AboutSewction/>
   </div>
  )
}

export default About