import React, { useState } from 'react';

function UnlockForm() {
    const [message, setMessage] = useState('');
    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        phone: '',
        city: '', // Added city field
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(contactForm);
        try {
            const response = await fetch('https://corphelp.clearcover.in/api/get-user-campaign-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactForm),
            });

            console.log(response);
            if (response.ok) {
                setMessage('Your ticket has been raised!');
                console.log('Ticket Raised...');
    
                setTimeout(() => {
                    setMessage('');
                }, 2000);

                setContactForm({
                    name: '',
                    email: '',
                    phone: '',
                    city: '', // Reset city field
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="py-14 max-w-[100%] md:max-w-[80%] mx-auto flex felx-row justify-center items-center px-8">
        <div className="flex justify-center">
            <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                <h2 className="text-2xl font-bold text-center mb-6">
                    Have Questions? <span className="text-[#61BC6D]">We're Ready to Assist</span>
                </h2>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <label htmlFor="name" className="font-semibold text-gray-700">
                            Name
                        </label>
                        <input
                            name="name"
                            value={contactForm.name}
                            onChange={handleChange}
                            type="text"
                            id="name"
                            className="p-3 border outline-none border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#afdcb5] transition"
                            placeholder="Enter your name"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label htmlFor="email" className="font-semibold text-gray-700">
                            Email
                        </label>
                        <input
                            name="email"
                            value={contactForm.email}
                            onChange={handleChange}
                            type="email"
                            id="email"
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#afdcb5] transition"
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label htmlFor="phone" className="font-semibold text-gray-700">
                            Phone
                        </label>
                        <input
                            name="phone"
                            value={contactForm.phone}
                            onChange={handleChange}
                            type="text"
                            id="phone"
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#afdcb5] transition"
                            placeholder="Enter your phone number"
                            required
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label htmlFor="city" className="font-semibold text-gray-700">
                            City
                        </label>
                        <input
                            name="city"
                            value={contactForm.city}
                            onChange={handleChange}
                            type="text"
                            id="city"
                            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#afdcb5] transition"
                            placeholder="Enter your city"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-[#61BC6D] text-white p-3 rounded-md font-bold hover:bg-[#4CAF50] transition"
                    >
                        Send
                    </button>
                </div>

                {message && (
                    <div className="text-center text-green-600 font-semibold mt-4">{message}</div>
                )}
            </form>
        </div>
    </div>
    );
}

export default UnlockForm;
