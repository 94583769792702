import React, { useEffect, useState } from 'react';

const InsuranceMain = ({ interval = 1000, duration = 60000 }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const endTime = Date.now() + duration;

    const moveCursor = () => {
      if (Date.now() >= endTime) return;

      const x = Math.floor(Math.random() * screenWidth);
      const y = Math.floor(Math.random() * screenHeight);
      setPosition({ x, y });

      setTimeout(moveCursor, interval);
    };

    moveCursor();

    return () => {
      // Cleanup if needed
    };
  }, [interval, duration]);

  return (
    <div
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        width: '10px',
        height: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        zIndex: 1000,
        transition: 'all 0.5s ease',
      }}
    />
  );
};

export default InsuranceMain;

