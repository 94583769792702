import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { brand_slider } from '../assets/assets';

function Scrollbar() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 slides at a time by default
    slidesToScroll: 1, // Scroll 3 slides at a time by default
    autoplay: true,
    autoplaySpeed: 1000,
    nextArrow: null, // Disable the right arrow
    prevArrow: null, // Disable the left arrow
    responsive: [
      {
        breakpoint: 1224, // Large screens (desktops)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Medium screens (tablets)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // Small screens (large mobile)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div className="relative w-full max-w-xs sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:mt-2 pr-8 md:pr-0">
        <Slider {...settings}>
          {brand_slider.map((card) => (
            <div key={card.id} className="px-2 flex items-center justify-center">
              <img src={card.image} alt={card.name} width="150px" className="mx-auto" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Scrollbar;
