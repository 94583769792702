import React, { useState, useEffect } from 'react';
import Herosec from '../sections/HomePages/Herosec';
import HomeSection from '../sections/HomePages/HomeSection';
import { FaArrowUp } from "react-icons/fa";
import { assets } from '../assets/assets';

function Home() {
  const [showArrow, setShowArrow] = useState(false);

  // Function to track scroll position
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  };

  // Scroll to top on arrow click
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // UseEffect to track scrolling
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Herosec />
      <HomeSection />

      {/* Scroll Up Arrow */}
      {showArrow && (
        <div
          className="fixed bottom-10 right-10 text-[#61BC6D] cursor-pointer  animate-bounce transition-transform transform hover:scale-110"
          onClick={scrollToTop}
        >
          {/* <img src={assets.up_arrow} alt="" width="50px"  /> */}
          {/* <img src={assets.up_arrow1} alt="" width="50px"  /> */}
          <img src={assets.up_arrow2} alt="" width="50px"  />
        </div>
      )}
    </div>
  );
}

export default Home;
