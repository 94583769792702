import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function GroupAccidentalInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Group Accident Insurance</h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Group Accident Insurance? </h1>
        <p> Group Accident Insurance covers the risk of bodily injuries arising directly from accidents caused by external, violent, and visible means, resulting in death or disablement, either permanent or temporary. This type of insurance can be taken in addition to a Workmen's Compensation (WC) policy to provide extra employee benefits since WC compensation is often insufficient. It is a benefits policy and cannot replace a WC policy.  </p>

        <h1 className=' text-lg md:text-xl font-bold'>Benefits of Group Accident Insurance  </h1>
        <p>Accidents can significantly disrupt a family's financial stability. An accident can lead to severe economic problems for a breadwinner, undermining the comfort and security they work hard to provide. Group Accident Insurance can help secure employees' financial well-being, boosting morale and loyalty.  </p>

        
        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include:</h1>
         
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
               <li className='text-md font-bold'>Comprehensive Coverage:</li>
               <p>Includes accidental death, total or partial disability, and reimbursement of medical expenses.</p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Employee Protection: </li>
                <p>Covers any unforeseen events, whether due to employment or otherwise. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Customizable Sum Insured: </li>
                <p>Employers can choose a sum insured based on salary multiples or according to the designation. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Ambulance Charges:</li>
                <p> Reimbursed if the insured is taken to a facility by an ambulance </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Corporate Buffer </li>
                <p>A corporate buffer can be utilized when the claim exceeds the employee's average sum insured.   </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Transportation of Mortal Remains: </li>
                <p>Reimbursement for transporting the body from the accident site to the healthcare facility, residence, or crematorium ground </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Weekly Compensation:</li>
                <p> Paid if the policyholder is entirely disabled temporarily due to an accident. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Compensation for Broken Bones:</li>
                <p> Lump sum payment based on the policy details.</p>
            </ul>
             
        </div>
             

        <h1 className=' text-lg md:text-xl font-bold'>Who Can Buy It? </h1>
        <p>Group Accident Insurance is ideal for both small and large companies. It is a comprehensive cover that can be customized to meet the needs of any group, providing benefits in case of unforeseen events, such as accidental death or injury, unrelated to occupational diseases. </p>

        <h1 className=' text-lg md:text-xl font-bold'>Coverage Includes:</h1>
         
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
               <li className='text-md font-bold'>Accidental Death:</li>
               <p>The insurer pays the principal amount in case of demise due to an accident. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Disability:</li>
                <p>Compensation is provided up to the sum assured for partial or permanent disability. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Additional Benefits:</li>
                <p>Cover ambulance charges, transportation of mortal remains, weekly compensation, and compensation for broken bones. </p>
            </ul>
           </div>

        </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
    </div>
  )
}

export default GroupAccidentalInsurance