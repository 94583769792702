import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function GeneralLiabilityInsurance() {
  return (
    <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>General Liability Insurance  </h1>
            </div>
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>What is General Liability Insurance?   </h1>
                <p>General Liability Insurance provides coverage for businesses against claims of bodily injury, property damage, and personal injury. It protects businesses from financial losses arising from legal liabilities due to accidents or injuries on the premises.  </p>


                <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Legal Protection: </li>
                        <p>Covers legal fees and settlements.</p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Financial Security: </li>
                        <p> Protects against significant financial losses. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Reputation Management: </li>
                        <p>  Helps maintain the business's reputation by handling claims efficiently. </p>
                    </ul>

                </div>

                <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Bodily Injury:</li>
                        <p> Coverage for medical expenses and legal fees if someone is injured on your property. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Property Damage:</li>
                        <p>Covers damages to third-party property. </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Personal Injury:</li>
                        <p>   Protection against claims of libel, slander, and defamation.  </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Product Liability:</li>
                        <p> Coverage for damages caused by products sold by the business. </p>
                    </ul>
                </div>
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
  )
}

export default GeneralLiabilityInsurance