import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function OfficePackagePolicy() {
  return (
    <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Office Package Policy  </h1>
            </div>
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>What is an Office Package Policy?  </h1>
                <p> An Office Package Policy is a comprehensive insurance solution that protects office premises from various risks. It includes coverage for fire, burglary, machinery breakdown, liability, and more, ensuring the smooth operation of your business. </p>


                <h1 className=' text-lg md:text-xl font-bold'>Benefits of Office Package Policy :</h1>

                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Comprehensive Coverage:</li>
                        <p>Covers multiple risks under one policy.</p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Customizable: </li>
                        <p> Tailored to meet the specific needs of your office. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Peace of Mind: </li>
                        <p> Reduces financial stress by covering various potential losses. </p>
                    </ul>

                </div>

                <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Fire and Allied Perils:</li>
                        <p> Protection against fire, lightning, explosion, and more. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Burglary and Theft: </li>
                        <p>Coverage for losses due to burglary or theft.  </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Machinery Breakdown:</li>
                        <p>  Insurance for machinery and equipment breakdown.  </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Liability Coverage:</li>
                        <p>  Protects against third-party liabilities.</p>
                    </ul>
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Business Interruption:</li>
                        <p> Covers loss of income due to insured perils. </p>
                    </ul>
                </div>



            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
  )
}

export default OfficePackagePolicy