import React from 'react'
import { GiPlainCircle } from 'react-icons/gi'

function TermCondition() {
  return (<>
   <div className="">
   <div className="flex flex-col gap-6 bg-[#F4F7FA] justify-center py-20 items-center">
          
          <h1 className='text-center text-[22px] sm:text-4xl font-bold'>Terms & Conditions</h1>
          <div className="flex flex-col gap-1 text-[10px] sm:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wider  
          text-center text-[#161C2D]">
              <p>Clearcover is committed to respecting your privacy and understands the importance of maintaining  </p> 
              <p> control over the personal information you share with us and our partners.</p>
          </div>
   </div>

      <div className="py-8 ">
         <div className="max-w-[700px] mx-auto flex flex-col gap-7 text-[14px] px-4">
             <p>During your visit to this website, we do not collect any personally identifiable information. However, if you provide such information to request additional details or make contact, we will securely store and use it to address your inquiries.Clearcover does not disclose your personal information to others unless it is necessary to provide the requested information. We strive to maintain accurate records, and the accuracy of our data depends on the information you provide.For any questions or comments about our privacy policy or if you wish to access or update your personal information, please email us at <span className="text-[#1E90FF]">support@clearcover.com</span></p>

          <h1 className=' text-lg md:text-xl font-bold'>License</h1>

          <p>MDH Insurance is a registered Direct Broker |Registration No. 596, Registration Code No. DB 652/16,Valid till 30/04/2026,License category- Direct Broker (General)| Visitors are hereby informed that their information submitted on the website may be shared with insurers.Product information is authentic and solely based on the information received from the insurers.</p>

          <div className="flex flex-col">
          <h2 className="text-lg font-bold">Security</h2>
          <ul className="mt-3 space-y-4">
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              <span className='font-semibold'>Data Security:</span> We safeguard your personal and financial information through encryption and secure storage.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              <span className='font-semibold'>Fraud Protection:</span>We provide monitoring to protect against fraudulent activities in your insurance transactions.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              <span className='font-semibold'>Regulatory Compliance: </span>Ongoing compliance with local and international insurance regulations to protect clients from legal and financial risks.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              <span className='font-semibold'>Claims Protection: </span>Assistance with secure claim filing, ensuring that claims are processed efficiently without risk of data compromise.
              </p>
            </li>
          </ul>
        </div>

         <h1 className='text-lg md:text-xl font-bold'>Policy Information from Third Parties</h1>
         <p>Our website may include content or resources from third-party providers, such as videos, articles, and other media. Any third-party content operates independently, and interactions with it will be governed by the privacy and data policies of the external provider.</p>

         <p>These third-party sites may collect personal information, use cookies, embed additional tracking mechanisms, and monitor your interactions with their content, including data tracking during your visit.</p>

         <h1 className='text-lg md:text-xl font-bold'>Updates to Policy and Terms</h1>
         <p>We reserve the right to update our terms and policies at any time. Should any changes occur, we will notify our registered policyholders via email, outlining any significant updates. You can also review updates on this page regularly for the most recent changes.</p>
         </div>
      </div>
   </div>
       
</> )
}

export default TermCondition