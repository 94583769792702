import React from "react";
import { assets } from "../../assets/assets";

function FirstBlog() {
  return (
<>
    <div className="py-16  flex flex-col justify-center items-center text-[22px]  bg-[#f4f7fa]">
        <div className=" max-w-[90%] md:max-w-[60%] mx-auto grid grid-cols-1 lg:grid-cols-2 items-center gap-6 lg:gap-20 ">

            <div className="bg-[#C9F0FE] rounded-lg ">
                <div className="flex flex-row justify-around mt-6">
                        <div className="flex flex-col text-center text-[14px] font-bold">
                            <h1>Network Hospital</h1>
                            <h1>v/s</h1>
                            <h1>Non Network Hospital</h1>   
                        </div>
                        <div className="">
                            <img src={assets.blog_networking} alt="" />
                        </div>
                </div>
                <div className="flex flex-row justify-center">
                    <img src={assets.blog_hospital_building} alt="" />
                </div>
            </div>

            <div className="flex flex-col">
                 <p className="text-[20px] font-bold">All You Need to Know About Network and Non-Network Hospitals: An Essential Guide for Health Insurance</p>
                 <span className="text-[10px] font-thin tracking-wider">11/09/2024 by clearcover</span>
            </div>
        </div>
    </div>

    <div className="max-w-[90%] md:max-w-[60%] mx-auto grid grid-cols-1 gap-8 py-10 ">
        <p className="text-[16px] text-[#161C2D]">When selecting a health insurance plan, understanding the difference between network and non-network hospitals is key to navigating medical expenses effectively. Network hospitals have agreements with insurers to offer cashless treatment, making it easier for patients as the insurance provider directly settles bills. Non-network hospitals require patients to pay upfront and seek reimbursement later, making the process lengthier.</p>

        <h1 className="text-xl font-semibold">Network Hospitals:</h1>

        <p className="text-[16px] text-[#161C2D]">Cashless Facility: This allows the insurer to settle expenses directly with the hospital, making it the most hassle-free option. Policyholders only pay deductibles as per policy terms, making it highly convenient, especially in emergencies.</p>

        <p className="text-[16px] text-[#161C2D]">Without Cashless Facility: Even in network hospitals, if the policyholder doesn’t avail the cashless facility, they have to pay for the treatment upfront and claim reimbursement later, which adds a waiting period for claims to be processed.</p>

        <h1 className="text-xl font-semibold">Non-Network Hospitals:</h1>

        <p className="text-[16px] text-[#161C2D]">In non-network hospitals, there’s no cashless option, so you bear the entire treatment cost and then apply for reimbursement. The process involves submitting bills and may take longer to settle, causing added financial pressure, especially during emergencies.</p>

        <div className="">
            <h1 className="text-xl font-semibold">A Comparative Analysis: Network vs Non-Network Hospitals</h1>
            <p  className="text-[16px] text-[#161C2D]">To give you a clearer picture, here’s a side-by-side comparison of the characteristics of network and non-network hospitals.</p>
        </div>

        <div className="flex flex-row justify-center">
              <img src={assets.blog_table} alt="" />
        </div>

        <p className="text-[16px] text-[#161C2D]">In light of this comparison, it’s evident that availing medical treatment from a network hospital with a cashless facility offers the most convenience. However, it’s essential to note that your choice of hospital, network or non-network, might also depend on factors like the specific treatment you need, the hospital’s proximity, and availability in case of an emergency.</p>

        <h1 className="text-xl font-semibold">Choosing the Best Option</h1>

        <p className="text-[16px] text-[#161C2D]">Choosing a network hospital, especially with a cashless facility, is the best way to ensure a stress-free medical experience. However, your decision may also depend on hospital proximity, the treatment required, and your insurance policy terms. Understanding these distinctions helps you prepare for unexpected health crises without worrying about upfront costs.</p>

        <h1 className="text-xl font-semibold">Conclusion</h1>

        <p className="text-[16px] text-[#161C2D]">Understanding the difference between network and non-network hospitals, and how the cashless facility works, is crucial for making informed decisions. Whether choosing a hospital or navigating an insurance policy, this knowledge ensures smoother treatment experiences, helping you save time, money, and stress during health emergencies.</p>







    </div>
    </>
  );
}

export default FirstBlog;
