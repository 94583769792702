import React, { useState } from 'react'

function Login() {
    const [signIn, setSignIn]=useState(false);
    


    const handlesSignIn = () =>{
       setSignIn(!signIn)
    //    setTimeout(()=>{
    //     setSignIn(!signIn)
    //    }, 1000)
    }
  return (
    <div className="py-14 md:py-20 ">
          <div className="max-w-[80%] mx-auto flex flex-col gap-5 items-center md:mt-20">
              <h1 className='text-center text-[22px] sm:text-4xl font-bold'>Login</h1>

              <div className="flex flex-col gap-1 text-[10px] sm:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wider  
                  text-center text-[#161C2D]">
                <p>To get started, you need to sign in here.</p> 
              </div>

              <form style={{animation: signIn ? "fadeIn 1s ease-in-out" : "fadeIn 1s ease-in-out"}}
                action="" className='mt-8'>
                      <div className=" grid gris-cols-1 gap-4 md:w-[300px] bg-[#fff]  rounded-lg shadow-xl p-4 ">
                             {signIn ? <div className="grid grid-cols-1 gap-1">
                                            <label htmlFor="name" className='font-medium text-md'>User Name</label>
                                            <input type="text" placeholder='i.e. John Doe' className='p-2 rounded-md outline-none shadow-sm' />
                                       </div>:""}

                             <div className="grid grid-cols-1 gap-1">
                                <label htmlFor="name" className='font-medium text-md'>Email</label>
                                <input type="email" placeholder='i.e. john@mail.com' className='p-2 rounded-md outline-none shadow-sm' />
                             </div>

                             <div className="grid grid-cols-1 gap-1">

                                <div className="flex flex-row items-center justify-between">
                                    <label htmlFor="password" className='font-medium text-md'>Password</label>
                                    <label htmlFor="password" className=' text-[#61BC6D] text-[12px]'>Forgot password?</label>
                                </div>
                                
                                <input type="passwod" placeholder='i.e. 123-456-7890' className='p-2 rounded-md outline-none shadow-sm' />
                             </div>

                             <div className="flex flex-row  gap-3">
                                <input type="checkbox" placeholder='i.e. john@mail.com' className='p-2 rounded-md outline-none' />
                                <label htmlFor="name" className='text-[14px]'>Remember me</label>
                             </div>

                             

                             <div className="flex flex-row justify-center items-center bg-[#61BC6D] rounded-md mt-4">
                                <button className='p-3 rounded-md text-[#ffff] font-bold'>{signIn ? 'Sign up':'Sign In'}</button>
                             </div>
                      </div>
               </form>
               <p className=' text-[14px] sm:text-[16px] mt-6 cursor-pointer'>
                            Don’t have an account?
                           <span  onClick={handlesSignIn}
                              className='text-[#473BF0]'>{signIn? ' Sign in': ' Create an account'}</span>
                </p>

          </div>
    </div>
  )
}

export default Login