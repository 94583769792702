import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function PlantMachineryInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Plant & Machinery Insurance </h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Plant & Machinery Insurance?  </h1>
        <p>Plant & Machinery Insurance covers the risks associated with operating industrial equipment and machinery. This policy ensures businesses can quickly repair or replace damaged machinery, minimizing downtime and financial losses.   </p>


        <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Comprehensive Protection: </li>
                <p>  Coverage for a wide range of machinery and equipment. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Business Continuity:</li>
                <p>  Minimizes downtime and financial impact of machinery breakdowns. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Customizable Policies:</li>
                <p>Tailored to specific machinery and operational needs.  </p>
            </ul>

        </div>

        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Breakdown Coverage:</li>
                <p>Insurance for mechanical and electrical breakdowns. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Accidental Damage:</li>
                <p> Coverage for accidental damage to machinery. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Replacement Costs:</li>
                <p> Compensation for repair or replacement of damaged equipment. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Third-Party Liability:</li>
                <p> Protection against liabilities arising from machinery operations. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Business Interruption:</li>
                <p> Covers income loss due to machinery breakdowns.</p>
            </ul>
        </div>
    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default PlantMachineryInsurance