import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function ConstructionInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Construction Insurance   </h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Construction Insurance?  </h1>
        <p>Construction Insurance covers construction projects against damage, theft, and third-party liabilities. It is designed to protect contractors, builders, and property owners from financial losses during construction.   </p>


        <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Project Protection:</li>
                <p>Ensures the continuity and completion of projects. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Financial Security:</li>
                <p>  Covers costs associated with damages and delays. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Liability Coverage: </li>
                <p>  Protects against legal liabilities. </p>
            </ul>

        </div>

        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Contractor's All Risk:</li>
                <p>  Comprehensive coverage for construction sites. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Third-Party Liability:</li>
                <p> Protection against claims from third parties.</p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Equipment Coverage:</li>
                <p> Insurance for construction machinery and equipment.  </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Delay in Start-Up:</li>
                <p>Covers financial losses due to project delays. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Employee Protection: </li>
                <p>  Coverage for injuries to construction workers.</p>
            </ul>
        </div>
    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default ConstructionInsurance