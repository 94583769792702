import React, { useState } from "react";
import { assets, career_Card, jobOpening_cards } from "../assets/assets";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
function Career() {
  const [isClicked, setIsClicked] = useState(null);

  const handleClick = (index) => {
    console.log("hello");
    setIsClicked(index);
  };
  console.log(isClicked);
  return (
    <>
      <div className=" flex flex-col gap-30 ">
        <div className="flex flex-col  items-center gap-4 md:gap-6 bg-[#F4F7FA] py-20 ">
          <h1 className="text-[22px] sm:text-5xl font-bold text-center ">
            Join <span className="text-[#61BC6D]">Clearcover</span> – Build the
            Future of Insurance
          </h1>
          <div className="flex-col gap-0.5 text-[10px] sm:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wider text-center text-[#161C2D]">
            <p>
              At Clearcover, we're redefining insurance through innovation and
              technology. Our team is committed to providing
            </p>
            <p>
              the best insurance experience, helping customers access affordable
              and reliable coverage with ease.
            </p>
          </div>
        </div>

        {/* <div className="">
          <img src={assets.career_video} alt="" width="100%" />
          <img src={assets.team_group} alt="" width="100%"  className="object-top"/>
        </div> */}

        <div className="relative overflow-hidden mt-[-11.2%]">
            <img 
                src={assets.team_career_group} 
                alt="" 
                className="object-top w-full h-auto" 
                style={{ clipPath: 'inset(15% 0 0% 0)' }}
            />
        </div>

        <div className="flex flex-col  items-center gap-6 md:gap-8 py-16  px-4">
          <h1 className="text-[22px] sm:text-4xl font-bold ">
            Why you should join
            <span className="text-[#61BC6D]"> Clearcover?</span>
          </h1>
          <div className="flex-col gap-0.5 text-[10px] sm:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wider text-center text-[#161C2D]">
            <p>
              Join a team where your skills can shape the future of insurance
              innovation.{" "}
            </p>
            <p>
              At Clearcover, we are redefining how people experience insurance
              through technology and excellent service.
            </p>
          </div>
        </div>

        <div className=" max-w-[90%] md:max-w-[70%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  md:gap-10 items-center justify-center mt-[-30px] md:mt-0 ">
          {career_Card.map((item, index) => (
            // <div className="w-[300px] bg-slate-400">
            <div className="grid grid-flow-col-dense gap-5 p-5 ">
              <div className="flex items-start">
                <img src={item.icon} alt="" />
              </div>
              <div className="flex flex-col items-start gap-3">
                <p className="text-lg md:text-xl font-bold">{item.title}</p>
                <p>{item.content}</p>
              </div>
            </div>
            // </div>
          ))}
        </div>
      </div>

      {/* // ################## Current Job opening ############################# */}

      <div className="max-w-[80%] mx-auto py-10  px-8">
        <h1 className="text-center text-[22px] sm:text-4xl font-bold mb-6">
          Current Job Openings
        </h1>

        <div className="flex flex-col sm:flex-row gap-6 mb-8">
          <div className="flex flex-col gap-2">
            <label htmlFor="department" className="">
              Department
            </label>
            <select
              name="department"
              id="department"
              className=" font-light rounded-md outline-none p-3 border border-gray-200 bg-[#F4F7FA]"
            >
              <option value="All Departments" className=" text-gray-700">
                All Departments
              </option>
              <option
                value="Business Development Lead"
                className=" text-gray-700"
              >
                Business Development Lead
              </option>
              <option value="Customer Happiness" className=" text-gray-700">
                Customer Happiness
              </option>
              <option value="Endorsement Operations" className=" text-gray-700">
                Endorsement Operations
              </option>
              <option value="Sales" className=" text-gray-700">
                Sales
              </option>
              <option value="SMB Sales" className=" text-gray-700">
                SMB Sales
              </option>
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="location" className="">
              Location
            </label>
            <select
              name="location"
              id="location"
              className="font-light rounded-md outline-none p-3 border border-gray-200 bg-[#F4F7FA]"
            >
              <option value="All Locations">All Locations</option>
              <option value="Bangalore">Bangalore</option>
            </select>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-t border-b border-gray-200 shadow-lg">
            <thead className="bg-[#F4F7FA] font-semibold">
              <tr>
                <th className="px-6 py-3 text-left border-b border-gray-200">
                  Role
                </th>
                <th className="px-6 py-3 text-left border-b border-gray-200">
                  Location
                </th>
                <th className="px-6 py-3 text-left border-b border-gray-200">
                  Department
                </th>
                <th className="px-6 py-3 text-left border-b border-gray-200">
                  Experience
                </th>
                <th className="px-6 py-3 text-left border-b border-gray-200">
                  Openings
                </th>
                <th className="px-6 py-3 text-left border-b border-gray-200"></th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {jobOpening_cards.length === 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    className="text-center py-4 text-lg font-bold text-[#61BC6D]"
                  >
                    Right now, we don't have any job openings.
                  </td>
                </tr>
              ) : (
                jobOpening_cards.map((job, index) => (
                  <tr
                    key={index}
                    className="border-b  transition-colors duration-200"
                  >
                    <td className="px-6 py-4 font-bold border-b border-gray-200 text-[#61BC6D]">
                      {job.role}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      {job.location}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      {job.department}
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      {job.experience}
                    </td>
                    <td className="px-6 py-4 text-center border-b border-gray-200">
                      {job.opening}
                    </td>
                    <td className="cursor-pointer text-[#61BC6D] text-center border-b border-gray-200">
                      <Link to={job.path} className="text-[#61BC6D]">
                        {job.view}
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Career;
