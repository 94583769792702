// import React, { useState } from 'react';
// import { RxCross2 } from "react-icons/rx";


// const GwetQuoteModal = ({ isOpen, onClose }) => {

//   const [message, setMessage] = useState(''); 
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     service:''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//         const response = await fetch("https://corphelp.clearcover.in/api/get-a-quote",{
//             method: "POST",
//             headers: {
//                 "Content-Type":"application/json"
//             },
//             body:JSON.stringify(formData),
//         });
//         console.log(response)
//         if(response.ok){
//             setMessage('Your ticket has been raised!');
//             console.log("Ticket Raised...");

//             setTimeout(() => {
//                 setMessage(''); // Clear message after 2 seconds
//                 onClose(); // Close the modal after message disappears
//               }, 2000);
//         }
        
//     } catch (error) {
//         console.error(error)
//     }
     
//     setTimeout(()=>{
//         onClose(); 
//     },2000) // Close the modal after submission
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50">
//       <div className="bg-black bg-opacity-25 absolute inset-0" onClick={onClose}></div>
//       <div className="bg-white rounded-lg p-8 shadow-lg z-10 ">
//         <div className="flex flex-row justify-between mb-4">
//             <h2 className="text-xl  text-[#61BC6D] font-extrabold ">Get A Quote</h2>
//             <RxCross2 onClick={onClose} size={30} className='text-[#61BC6D] font-extrabold hover:text-red-800'/> 
//         </div>
//         {/* {message && <div className="text-center text-green-600 font-bold mb-4">{message}</div>} */}
//         <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
//         <div className="grid grid-cols-1 gap-1">
//              <label htmlFor="name"  className='font-medium'>Name</label>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="email" className='font-medium'>Email</label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//           </div>
//           <div className="grid grid-cols-1 gap-1">
//           <label htmlFor="phone" className='font-medium'>Phone</label>
//             <input
//               type="number"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//                                 <label htmlFor="service" className='font-medium'>Which service do you need?</label>
//                                 <select  name="service"
//                                          value={formData.service}
//                                          onChange={handleChange}
//                                          className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'
//                                          required >
//                                      <option value="">Select</option>
//                                      <option value="Group Health Insurance">Group Health Insurance</option>
//                                      <option value="WFire & Burglary Insurance">Fire & Burglary Insurance</option>
//                                      <option value="Office Package Policy">Office Package Policy</option>
//                                      <option value="Group Accidental Insurance">Group Accidental Insurance</option>
//                                      <option value="General Liability Insurance">General Liability Insurance</option>
//                                      <option value="Aviation Insurance">Aviation Insurance</option>
//                                      <option value="Construction Insurance">Construction Insurance</option>
//                                      <option value="Marine Insurance">Marine Insurance</option>
//                                      <option value="Cyber Security Insurance">Cyber Security Insurance</option>
//                                      <option value="Director's & Officers Liability Insurance">Director's & Officers Liability Insurance</option>
//                                      <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
//                                      <option value="Professional Indemnity Insurance">Professional Indemnity Insurance</option>
//                                      <option value="Workmen Compensation Insurance">Workmen Compensation Insurance</option>
//                                 </select>
//              </div>
//             <div className="flex justify-center items-center bg-[#61BC6D] rounded-md mt-4">
//                 <button type="submit" className="p-3 rounded-md text-white font-bold">
//                          Talk to an Expert
//                 </button>
//             </div>
//           </form>
//           {message && <div className="text-center text-green-600 font-bold mt-3">{message}</div>}
//       </div>
//     </div>
//   );
// };

// export default GwetQuoteModal;

// import React, { useState } from 'react';
// import { RxCross2 } from 'react-icons/rx';

// const GetQuoteModal = ({ isOpen, onClose }) => {
//   const [message, setMessage] = useState('');
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     service: '',
//   });

//   const [errors, setErrors] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     service: '',
//   });

//   // Validation functions
//   const nameValidation = (name) => {
//     // if (!name.trim()) return 'Please provide a valid name';
//     // if (name.length+1 < 3) return 'Full Name is required';
//     if (name.length > 50) return 'Name cannot exceed 50 characters';
//     // if (!/^[A-Za-z\s]+$/.test(name)) return 'Only letters and spaces allowed';
//     if (/\s{2,}/.test(name)) return 'Name cannot contain consecutive spaces';
//     if (/^\s|\s$/.test(name)) return 'Name cannot start or end with a space';
//     return '';
//   };

//   const emailValidation = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
//     const consecutiveDotsRegex = /\.{2,}/;
//     const domainPart = email.split('@')[1];
  
//     // if (!email.trim()) return 'Email is required';
//     if (!emailRegex.test(email)) return 'Invalid email format (e.g., mail@example.com)';
//     if (consecutiveDotsRegex.test(email))
//       return 'Email address cannot contain consecutive dots';
//     if (email.startsWith('.') || email.endsWith('.'))
//       return 'Email address cannot start or end with a dot';
//     if (!domainPart || domainPart.split('.').length < 2)
//       return 'Email must include a valid domain (e.g., example.com)';
//     if (domainPart.split('.')[0].length < 2)
//       return 'Domain part of email must be valid (e.g., example.com)';
  
//     // Known domain validation
//     const knownDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];
//     const domain = domainPart.toLowerCase();
//     if (!knownDomains.some((d) => domain.endsWith(d))) {
//       return 'Email domain is not recognized. Use a common email provider.';
//     }
  
//     return '';
//   };
  

//   const phoneValidation = (phone) => {
//     const phoneRegex = /^[0-9]{10}$/; // For 10-digit phone numbers only
//     if (!phone.trim()) return 'Phone number is required';
//     if (!phoneRegex.test(phone)) return 'Phone number must be 10 digits';
//     return '';
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));

//     // Validate as the user types
//     if (name === 'name') setErrors((prev) => ({ ...prev, name: nameValidation(value) }));
//     if (name === 'email') setErrors((prev) => ({ ...prev, email: emailValidation(value) }));
//     if (name === 'phone') setErrors((prev) => ({ ...prev, phone: phoneValidation(value) }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const nameError = nameValidation(formData.name);
//     const emailError = emailValidation(formData.email);
//     const phoneError = phoneValidation(formData.phone);

//     if (nameError || emailError || phoneError) {
//       setErrors({ name: nameError, email: emailError, phone: phoneError });
//       return;
//     }

//     try {
//       const response = await fetch('https://corphelp.clearcover.in/api/get-a-quote', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });
//       if (response.ok) {
//         setMessage('Your ticket has been raised!');
//         setTimeout(() => {
//           setMessage('');
//           onClose(); // Close the modal
//           // Clear form data after successful submission
//           setFormData({
//             name: '',
//             email: '',
//             phone: '',
//             service: '',
//           });
//         }, 2000);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50">
//       <div className="bg-black bg-opacity-25 absolute inset-0" onClick={onClose}></div>
//       <div className="bg-white rounded-lg p-8 shadow-lg z-10">
//         <div className="flex flex-row justify-between mb-4">
//           <h2 className="text-xl text-[#61BC6D] font-extrabold">Get A Quote</h2>
//           <RxCross2
//             onClick={onClose}
//             size={30}
//             className="text-[#61BC6D] font-extrabold hover:text-red-800"
//           />
//         </div>
//         <form onSubmit={handleSubmit} className="flex flex-col gap-4">
//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="name" className="font-medium">
//               Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//             <h1 className='h-[10px] mt-[-10px]'>{errors.name && <span className="text-red-600 text-[10px]">{errors.name}</span>}</h1>
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="email" className="font-medium">
//               Email
//             </label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//             <h1 className='h-[10px] mt-[-10px]'>{errors.email && <span className="text-red-600 text-[10px]">{errors.email}</span>}</h1>

//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="phone" className="font-medium">
//               Phone
//             </label>
//             <input
//               type="number"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//             <h1 className='h-[10px] mt-[-10px]'>{errors.phone && <span className="text-red-600 text-[10px]">{errors.phone}</span>}</h1>
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="service" className="font-medium">
//               Which service do you need?
//             </label>
//             <select
//               name="service"
//               value={formData.service}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             >
//               <option value="">Select</option>
//               <option value="Group Health Insurance">Group Health Insurance</option>
//               <option value="Fire & Burglary Insurance">Fire & Burglary Insurance</option>
//               <option value="Office Package Policy">Office Package Policy</option>
//               <option value="Group Accidental Insurance">Group Accidental Insurance</option>
//               <option value="General Liability Insurance">General Liability Insurance</option>
//               <option value="Aviation Insurance">Aviation Insurance</option>
//               <option value="Construction Insurance">Construction Insurance</option>
//               <option value="Marine Insurance">Marine Insurance</option>
//               <option value="Cyber Security Insurance">Cyber Security Insurance</option>
//               <option value="Director's & Officers Liability Insurance">
//                 Director's & Officers Liability Insurance
//               </option>
//               <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
//               <option value="Professional Indemnity Insurance">Professional Indemnity Insurance</option>
//               <option value="Workmen Compensation Insurance">Workmen Compensation Insurance</option>
//             </select>
//           </div>

//           <div className="flex justify-center items-center bg-[#61BC6D] rounded-md mt-4">
//             <button type="submit" className="p-3 rounded-md text-white font-bold">
//               Talk to an Expert
//             </button>
//           </div>
//         </form>
//         {message && <div className="text-center text-green-600 font-bold mt-3">{message}</div>}
//       </div>
//     </div>
//   );
// };

// export default GetQuoteModal;


// import React, { useState } from "react";
// import { RxCross2 } from "react-icons/rx";

// const GetQuoteModal = ({ isOpen, onClose }) => {
//   const [message, setMessage] = useState("");
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     service: "",
//   });

//   const [errors, setErrors] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     service: "",
//   });

//   // Validation functions
//   const nameValidation = (name) => {
//     if (!name.trim()) return "Name is required.";
//     if (name.length < 3) return "Name must be at least 3 characters.";
//     if (name.length > 50) return "Name cannot exceed 50 characters.";
//     if (/\s{2,}/.test(name)) return "Name cannot contain consecutive spaces.";
//     if (/^\s|\s$/.test(name)) return "Name cannot start or end with a space.";
//     return "";
//   };

//   const emailValidation = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
//     const consecutiveDotsRegex = /\.{2,}/;

//     if (!email.trim()) return "Email is required.";
//     if (!emailRegex.test(email)) return "Invalid email format (e.g., mail@example.com).";
//     if (consecutiveDotsRegex.test(email)) return "Email cannot contain consecutive dots.";
//     if (email.startsWith(".") || email.endsWith("."))
//       return "Email cannot start or end with a dot.";
//     return "";
//   };

//   const phoneValidation = (phone) => {
//     const phoneRegex = /^[0-9]{10}$/; // 10-digit phone number validation
//     if (!phone.trim()) return "Phone number is required.";
//     if (!phoneRegex.test(phone)) return "Phone number must be 10 digits.";
//     return "";
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Update form data
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));

//     // Dynamic validation
//     let error = "";
//     if (name === "name") error = nameValidation(value);
//     if (name === "email") error = emailValidation(value);
//     if (name === "phone") error = phoneValidation(value);

//     setErrors((prev) => ({
//       ...prev,
//       [name]: error,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const nameError = nameValidation(formData.name);
//     const emailError = emailValidation(formData.email);
//     const phoneError = phoneValidation(formData.phone);

//     // If there are validation errors, set them and return
//     if (nameError || emailError || phoneError) {
//       setErrors({ name: nameError, email: emailError, phone: phoneError });
//       return;
//     }

//     try {
//       const response = await fetch("https://corphelp.clearcover.in/api/get-a-quote", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         setMessage("Your ticket has been raised!");
//         setTimeout(() => {
//           setMessage("");
//           onClose(); // Close the modal
//           setFormData({
//             name: "",
//             email: "",
//             phone: "",
//             service: "",
//           });
//         }, 2000);
//       } else {
//         setMessage("Failed to submit the form. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setMessage("Something went wrong. Please try again.");
//     }
//   };

//   const handleClose = () => {
//     onClose();
//     setFormData({ name: "", email: "", phone: "", service: "" });
//     setErrors({ name: "", email: "", phone: "", service: "" });
//     setMessage("");
//   };


//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50">
//       <div className="bg-black bg-opacity-25 absolute inset-0" onClick={onClose}></div>
//       <div className="bg-white rounded-lg p-8 shadow-lg z-10">
//         <div className="flex flex-row justify-between mb-4">
//           <h2 className="text-xl text-[#61BC6D] font-extrabold">Get A Quote</h2>
//           <RxCross2
//           onClick={handleClose}
//             // onClick={onClose}
//             size={30}
//             className="text-[#61BC6D] font-extrabold hover:text-red-800 cursor-pointer"
//           />
//         </div>
//         <form onSubmit={handleSubmit} className="flex flex-col gap-4">
//           {/* Name Field */}
//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="name" className="font-medium">
//               Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               className={`p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px] ${
//                 errors.name ? "border-red-500" : ""
//               }`}
//               required
//             />
//             {errors.name && <span className="text-red-600 text-[10px]">{errors.name}</span>}
//           </div>

//           {/* Email Field */}
//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="email" className="font-medium">
//               Email
//             </label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               className={`p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px] ${
//                 errors.email ? "border-red-500" : ""
//               }`}
//               required
//             />
//             {errors.email && <span className="text-red-600 text-[10px]">{errors.email}</span>}
//           </div>

//           {/* Phone Field */}
//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="phone" className="font-medium">
//               Phone
//             </label>
//             <input
//               type="number"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//               className={`p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px] ${
//                 errors.phone ? "border-red-500" : ""
//               }`}
//               required
//             />
//             {errors.phone && <span className="text-red-600 text-[10px]">{errors.phone}</span>}
//           </div>

//           {/* Service Field */}
//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="service" className="font-medium">
//               Which service do you need?
//             </label>
//             <select
//               name="service"
//               value={formData.service}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             >
//               <option value="">Select</option>
//               <option value="Group Health Insurance">Group Health Insurance</option>
//               <option value="Fire & Burglary Insurance">Fire & Burglary Insurance</option>
//               <option value="Office Package Policy">Office Package Policy</option>
//               <option value="Group Accidental Insurance">Group Accidental Insurance</option>
//               <option value="General Liability Insurance">General Liability Insurance</option>
//               <option value="Aviation Insurance">Aviation Insurance</option>
//               <option value="Construction Insurance">Construction Insurance</option>
//               <option value="Marine Insurance">Marine Insurance</option>
//               <option value="Cyber Security Insurance">Cyber Security Insurance</option>
//               <option value="Director's & Officers Liability Insurance">
//                 Director's & Officers Liability Insurance
//               </option>
//               <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
//               <option value="Professional Indemnity Insurance">
//                 Professional Indemnity Insurance
//               </option>
//               <option value="Workmen Compensation Insurance">
//                 Workmen Compensation Insurance
//               </option>
//             </select>
//           </div>

//           {/* Submit Button */}
//           <div className="flex justify-center items-center bg-[#61BC6D] rounded-md mt-4">
//             <button type="submit" className="p-3 rounded-md text-white font-bold">
//               Talk to an Expert
//             </button>
//           </div>
//           {/* Background Overlay */}
//       <div
//         className="bg-black bg-opacity-25 absolute inset-0"
//         onClick={handleClose}
//       ></div>

//         </form>
//         {message && <div className="text-center text-green-600 font-bold mt-3">{message}</div>}
//       </div>
//     </div>
//   );
// };

// export default GetQuoteModal;

import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

const GetQuoteModal = ({ isOpen, onClose }) => {
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
  });

  // Validation functions
  const nameValidation = (name) => {
    if (!name.trim()) return "Name is required.";
    if (name.length < 3) return "Name must be at least 3 characters.";
    if (!/^[A-Za-z\s]+$/.test(name)) return 'Only letters and spaces allowed';
    if (name.length > 50) return "Name cannot exceed 50 characters.";
    if (/\s{2,}/.test(name)) return "Name cannot contain consecutive spaces.";
    if (/^\s|\s$/.test(name)) return "Name cannot start or end with a space.";
    return "";
  };

  const emailValidation = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
        const consecutiveDotsRegex = /\.{2,}/;
        const domainPart = email.split('@')[1];
      
        // if (!email.trim()) return 'Email is required';
        if (!emailRegex.test(email)) return 'Invalid email format (e.g., mail@example.com)';
        if (consecutiveDotsRegex.test(email))
          return 'Email address cannot contain consecutive dots';
        if (email.startsWith('.') || email.endsWith('.'))
          return 'Email address cannot start or end with a dot';
        if (!domainPart || domainPart.split('.').length < 2)
          return 'Email must include a valid domain (e.g., example.com)';
        if (domainPart.split('.')[0].length < 2)
          return 'Domain part of email must be valid (e.g., example.com)';
      
        // Known domain validation
        const knownDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];
        const domain = domainPart.toLowerCase();
        if (!knownDomains.some((d) => domain.endsWith(d))) {
          return 'Email domain is not recognized. Use a common email provider.';
        }
      
        return '';
      };

  const phoneValidation = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phone.trim()) return "Phone number is required.";
    if (!phoneRegex.test(phone)) return "Phone number must be 10 digits.";
    return "";
  };

  const serviceValidation = (service) => {
    if (!service) return "Please select a service.";
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    let error = "";
    if (name === "name") error = nameValidation(value);
    if (name === "email") error = emailValidation(value);
    if (name === "phone") error = phoneValidation(value);
    if (name === "service") error = serviceValidation(value);

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameError = nameValidation(formData.name);
    const emailError = emailValidation(formData.email);
    const phoneError = phoneValidation(formData.phone);
    const serviceError = serviceValidation(formData.service);

    if (nameError || emailError || phoneError || serviceError) {
      setErrors({
        name: nameError,
        email: emailError,
        phone: phoneError,
        service: serviceError,
      });
      return;
    }

    try {
      const response = await fetch("https://corphelp.clearcover.in/api/get-a-quote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage("Your ticket has been raised!");
        setTimeout(() => {
          setMessage("");
          handleClose();
        }, 2000);
      } else {
        setMessage("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Something went wrong. Please try again.");
      setTimeout(()=>{
        setMessage("")
      },2000)
    }
  };

  const handleClose = () => {
    onClose();
    setFormData({
      name: "",
      email: "",
      phone: "",
      service: "",
    });
    setErrors({
      name: "",
      email: "",
      phone: "",
      service: "",
    });
    setMessage("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black bg-opacity-25 absolute inset-0"
        onClick={handleClose}
      ></div>
      <div className="bg-white rounded-lg p-8 shadow-lg z-10 relative">
        <div className="flex justify-between mb-4">
          <h2 className="text-xl text-[#61BC6D] font-extrabold">Get A Quote</h2>
          <RxCross2
            onClick={handleClose}
            size={30}
            className="text-[#61BC6D] hover:text-red-800 cursor-pointer"
          />
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="grid gap-1">
            <label htmlFor="name" className="font-medium">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`p-2 rounded-md outline-none border ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
              required
            />
          <h1 className='h-[10px] mt-[-10px]'>{errors.name && <span className="text-red-600 text-[10px]">{errors.name}</span>}</h1>
          </div>

          <div className="grid gap-1">
            <label htmlFor="email" className="font-medium">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`p-2 rounded-md outline-none border ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
              required
            />
          <h1 className='h-[10px] mt-[-10px]'>{errors.email && <span className="text-red-600 text-[10px]">{errors.email}</span>}</h1>
          </div>

          <div className="grid gap-1">
            <label htmlFor="phone" className="font-medium">
              Phone
            </label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={`p-2 rounded-md outline-none border ${
                errors.phone ? "border-red-500" : "border-gray-300"
              }`}
              required
            />
          <h1 className='h-[10px] mt-[-10px]'>{errors.phone && <span className="text-red-600 text-[10px]">{errors.phone}</span>}</h1>
          </div>

          <div className="grid gap-1">
            <label htmlFor="service" className="font-medium">
              Which service do you need?
            </label>
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              className={`p-2 rounded-md outline-none border ${
                errors.service ? "border-red-500" : "border-gray-300"
              }`}
              required
            >
              <option value="">Select</option>
              <option value="Group Health Insurance">Group Health Insurance</option>
              <option value="Fire & Burglary Insurance">Fire & Burglary Insurance</option>
              <option value="Office Package Policy">Office Package Policy</option>
              <option value="Group Accidental Insurance">Group Accidental Insurance</option>
              <option value="General Liability Insurance">General Liability Insurance</option>
              <option value="Aviation Insurance">Aviation Insurance</option>
              <option value="Construction Insurance">Construction Insurance</option>
              <option value="Marine Insurance">Marine Insurance</option>
              <option value="Cyber Security Insurance">Cyber Security Insurance</option>
              <option value="Director's & Officers Liability Insurance">
                Director's & Officers Liability Insurance
              </option>
              <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
              <option value="Professional Indemnity Insurance">
                Professional Indemnity Insurance
              </option>
              <option value="Workmen Compensation Insurance">
                Workmen Compensation Insurance
              </option>
            </select>
            
          <h1 className='h-[10px] mt-[-10px]'>{errors.service && (
              <span className="text-red-600 text-[10px]">{errors.service}</span>
            )}</h1>

          </div>

          <button
            type="submit"
            className="bg-[#61BC6D] text-white font-bold p-3 rounded-md"
          >
            Talk to an Expert
          </button>
        </form>
        {message && <div className="text-center text-green-600 font-bold mt-3">{message}</div>}
      </div>
    </div>
  );
};

export default GetQuoteModal;
