import React, { useState } from "react";
import { GiPlainCircle } from "react-icons/gi";
import { TbBrandTwitterFilled } from "react-icons/tb";
import { FaFacebook } from "react-icons/fa";
import { PiInstagramLogoBold } from "react-icons/pi";
import { FaLinkedin } from "react-icons/fa";
import JobForm from "../../component/JobForm";

function PlacementAssociate() {
    const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  }; 
  return (
    <>
      <div className="py-20 flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>
          Placement Associate{" "}
          <span className="text-[#61BC6D]">(Liability)</span>
        </h1>
        {/* <h1 className='text-[#61BC6D]'>Insurance Service</h1> */}
      </div>

      <div className="max-w-[60%] mx-auto flex flex-col gap-6">
        <h1 className="text-3xl font-bold mt-14">Job Description</h1>

        <h4 className="text-lg font-bold ">
          Location: Bangalore (Work from office)
        </h4>

        <div className="flex flex-col ">
          <h5 className="text-lg font-semibold mb-1">About Clearcover:</h5>
          <p className="text-[14px]">
          Clearcover is committed to revolutionizing the insurance experience by delivering smarter coverage at lower costs. Our mission is to empower brokers to offer their clients top-tier insurance products with unparalleled efficiency. We believe that by leveraging cutting-edge technology, brokers can provide faster, more reliable, and transparent insurance solutions, ensuring customers receive optimal coverage at the best possible price. At Clearcover, we aim to simplify the process for brokers, policyholders, and insurers alike, driving a seamless and efficient insurance journey for all.
          </p>
          {/* <p className="text-[14px]">
          Clearcover was co-founded by Kyle Nakatsuji (JD, MBA - University of Wisconsin, former VC) and Derek Brigham (former CTO, American Family Ventures). Our innovative approach to insurance has attracted significant backing from top investors, including American Family Ventures, IA Capital Group, and OMERS Ventures. With our strong leadership and support from leading venture funds, Clearcover is driving the future of insurance through technology and customer-focused solutions. */}
          {/* </p> */}
        </div>

        <div className="flex flex-col">
          <h5 className="text-lg font-semibold mb-1">About the Role:</h5>
          <p className="text-[14px]">
            We are seeking an expert underwriter in the liability insurance
            domain who is eager to contribute and make an impact in the
            revolutionary start up space. In this role, you will review and
            analyze insurance applications for liability risks, determining
            appropriate coverage and premium rates while ensuring compliance
            with industry standards. You'll collaborate with agents, maintain
            accurate records, and support smooth workflow with onshore
            counterpart.
          </p>
        </div>

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">How You'll Make An Impact</h2>
          <ul className="mt-3 space-y-4">
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Review insurance applications related to property and liability
                risks, such as homeowners, commercial property, or general
                liability policies. Analyze the applicant's information,
                including property details, occupancy, claims history, and other
                relevant documents.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Determine the appropriate coverage limits, deductibles, and
                premium rates for each property and liability insurance policy
                based on the applicant's risk profile, industry standards, and
                underwriting guidelines of the carrier.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Communicating & collaborating with insurance agents and brokers
                to gather additional information, clarify details, and negotiate
                terms.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Ensure compliance with company policies, industry regulations,
                and legal requirements & follow established underwriting
                guidelines and procedures to maintain consistency and fairness
                in decision-making process.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Maintain accurate records of all underwriting decisions,
                correspondence, and supporting documents. Organize and manage
                underwriting files to ensure easy retrieval and reference.
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">Create & maintain internal reports.</p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Collaborate with onshore counterparts to ensure smooth workflow.
              </p>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">Requirements:</h2>
          <ul className="mt-3 space-y-3">
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              Bachelor's degree in a related field (e.g., finance, business, or insurance)
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
                Up to 3 years of experience as an Underwriter or in a similar role
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              Strong analytical and decision-making skills
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              Excellent attention to detail and accuracy
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              Knowledge of insurance industry regulations and guidelines
              </p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">Effective communication and interpersonal skills</p>
            </li>
            <li className="flex items-start">
              <span className="bg-black rounded-full mt-2 mr-3">
                <GiPlainCircle size={5} />
              </span>
              <p className="text-[14px]">
              Ability to work independently and as part of a team
              </p>
            </li>
          </ul>
        </div>
        <div className="mt-3 mb-10 flex flex-row items-center justify-between ">
               <div className="">
                    <button  onClick={openModal}
                           className="px-10 py-3 text-white font-semibold rounded-md bg-[#61BC6D] ">
                    Apply Now</button>
                <JobForm isOpen={isModalOpen} onClose={closeModal} />

               </div>

               <ul className="hidden lg:flex flex-row justify-center items-center gap-8 ">
                      <li> <a href="https://x.com/clearcover_insu"><TbBrandTwitterFilled size={30} className='text-[#E7E9ED] hover:text-sky-500 '/></a></li>
                      <li> <a href="https://www.facebook.com/clearcoverindia"><FaFacebook size={30} className='text-[#E7E9ED] hover:text-blue-600 '/></a></li>
                      <li> <a href="https://www.instagram.com/clearcover_insurance"><PiInstagramLogoBold size={30} className='text-[#E7E9ED] hover:text-red-500'/></a></li>
                      <li> <a href="https://www.linkedin.com/company/clearcover-insurance/"><FaLinkedin size={30} className='text-[#E7E9ED] hover:text-blue-700 '/></a></li>
                  </ul>
        </div>
    </div>
    </>
  );
}

export default PlacementAssociate;
