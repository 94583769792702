import React, { useEffect, useRef } from 'react';

function ScrollUpText() {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          textRef.current.classList.add('animate-slide-up');
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 ref={textRef} className="opacity-0 transform translate-y-10">
          Smooth Scroll Up Animation!
        </h1>
      </div>
    </div>
  );
}

export default ScrollUpText;
