// // import React from 'react'

// // function DownloadClaimForm() {

// //     const handleDownload = (filename) => {
// //         const link = document.createElement('a');
// //         link.href = `/${filename}`; // Files are now in the public folder, so it's the root path.
// //         link.download = filename;
// //         link.click();
// //     };
// //   return (
// //     <div className="flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-md w-full max-w-md mx-auto">
// //       <h1 className="text-lg font-bold mb-4">Download Files</h1>
// //       <button
// //         onClick={() => handleDownload('Claim form..pdf')}
// //         className="w-full py-2 mb-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
// //       >
// //         Download Claim Form
// //       </button>
// //       <button
// //         onClick={() => handleDownload('Check list_ New.docx')}
// //         className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
// //       >
// //         Download Checklist
// //       </button>
// //     </div>
// //   )
// // }

// // export default DownloadClaimForm



// import React from 'react'

// function DownloadClaimForm() {

//   const handleDownloadAll = () => {
//     const files = [
//       { filename: 'Claim form..pdf', filepath: '/Claim form..pdf' },
//       { filename: 'Check list_ New.docx', filepath: '/Check list_ New.docx' },
//     ];

//     files.forEach(file => {
//       const link = document.createElement('a');
//       link.href = file.filepath;
//       link.download = file.filename;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     });
//   };

//   return (
//     <div className="my-20 flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-md w-full max-w-md mx-auto">
//       <h1 className="text-lg font-bold mb-4">Download Files</h1>
//       <button
//         onClick={handleDownloadAll}
//         className="w-full py-2 mb-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
//       >
//         Download Both Files
//       </button>
//     </div>
//   )
// }

// export default DownloadClaimForm;


import React, { useEffect, useRef } from 'react';

function DownloadClaimForm() {
  const hasDownloaded = useRef(false); // Track if download has happened

  useEffect(() => {
    const handleDownloadAll = () => {
      const files = [
        { filename: 'Claim form..pdf', filepath: '/Claim form..pdf' },
        { filename: 'Check list_ New.docx', filepath: '/Check list_ New.docx' },
      ];

      files.forEach(file => {
        const link = document.createElement('a');
        link.href = file.filepath;
        link.download = file.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    };

    // Trigger the download only if it hasn't been done yet
    if (!hasDownloaded.current) {
      handleDownloadAll();
      hasDownloaded.current = true; // Set to true after download
    }
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <div className="my-20 flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-md w-full max-w-md mx-auto">
        <h1 className="text-lg font-bold mb-4">Downloading Files...</h1>
        <p>If the download doesn't start automatically, please <a href="/Claim form..pdf" download="Claim form..pdf">download the Claim Form</a> and <a href="/Check list_ New.docx" download="Check list_ New.docx">download the Checklist</a> manually.</p>
    </div>
  );
}

export default DownloadClaimForm;

