import React from 'react'
import {assets} from '../../assets/assets'

function HomeCrm() {
  return (<>
 <div className="relative z-[-2]">
    <div className="flex flex-row justify-between">
         <div className="bg-green-100 ">
            <img src={assets.home_crm_rectangle} alt="" className='w-[380px] translate-y-[-18%] z-[-2]'   />
         </div>
         <div className=" flex flex-col items-end justify-end top-10">
             <img src={assets.dot_1} alt="" className='' />
         </div>
    </div>

    <div className=" absolute max-w-[90%] md:max-w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-2 items-center justify-enter gap-32 px-4">
        <div className="flex felx-col items-center justify-center">
             <img src={assets.home_crm_mackbook} alt="" />
        </div>
        <div className="flex flex-col gap-5 text-center lg:text-start">
                       <div className="">
                            <div className="flex flex-col text-[25px] md:text-4xl lg:text-3xl font-bold ">
                                    <h1>Our Core Product: Dedicated CRM</h1>
                                </div>
                                <div className="flex-col gap-0.5  text-[10px] md:text-[12px] text-[#161C2D]">
                                    <p>At ClearCover, we believe in simplifying the insurance experience. Our Dedicated CRM</p>
                                    <p>system is designed to manage all your insurance needs in one place. It integrates all  </p>
                                    <p>aspects of policy management, claims processing, and customer support, ensuring  </p>
                                    <p>that you have complete control and visibility over your insurance portfolio. With our  </p>
                                    <p>CRM, you can:</p>
                                </div>
                       </div>

                       <div className="flex flex-col">
                           <div className="">
                               <h1 className='text-md font-semibold'>Manage Policies Efficiently: </h1>
                               <p className='text-[12px]'>Access all policy details and documents in one central location.</p>
                           </div>
                       </div>

                       <div className="flex flex-col">
                           <div className="">
                               <h1 className='text-md font-semibold'>Streamline Claims Processing:  </h1>
                               <p className='text-[12px]'>Submit and track claims seamlessly.</p>
                           </div>
                       </div>

                       <div className="flex flex-col">
                           <div className="">
                               <h1 className='text-md font-semibold'>Enhance Customer Support:  </h1>
                               <p className='text-[12px]'>Enjoy real-time support and assistance from our dedicated team.</p>
                           </div>
                       </div>
        </div>

    </div>

</div>


</> )
}

export default HomeCrm