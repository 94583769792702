import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
// #eeecec
function GroupHealthInsurance() {
    return (<>
        <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Group Health Insurance</h1>
            </div>

            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>What is Group Health Insurance (GHI)? </h1>
                <p>Group health insurance is a type of insurance that provides coverage to group members under one master health insurance policy. Typically, this group is an organization or company that purchases insurance as part of their employee benefits program. The insurance covers hospitalization expenses due to illness, disease, or accident during the policy period. Employees can make claims up to the sum insured they are eligible for under the policy. </p>

                <h1 className=' text-lg md:text-xl font-bold'>Features of Group Health Insurance Policy </h1>
                <p>Group Health Insurance policies offer numerous benefits not available in individual health insurance plans.  Here are some key features: </p>



                <div className="flex flex-col gap-4">
                    <div className='flex flex-col gap-1'>
                        <h2 className='text-md font-bold'>Smooth Enrollment</h2>
                        <p>Medical checks or declarations of good health are not required for employees to enrol. </p>
                    </div>
                    <div className='flex flex-col gap-1'>

                        <h2 className='text-md font-bold'>Higher Age Limits </h2>
                        <p>Coverage for employee parents can be extended, sometimes up to the age of 90. </p>
                    </div>
                    <div className='flex flex-col gap-1'>

                        <h2 className='text-md font-bold'>Maternity Cover </h2>
                        <p>Maternity expenses can be covered from day one, often up to three babies.  </p>
                    </div>
                    <div className='flex flex-col gap-1'>

                        <h2 className='text-md font-bold'>New Born Cover </h2>
                        <p>Newborn babies are covered from day one under the policy.  </p>
                    </div>
                    <div className='flex flex-col gap-1'>

                        <h2 className='text-md font-bold'>Corporate Buffer </h2>
                        <p>A corporate buffer can be utilized when the claim exceeds the employee's average sum insured.   </p>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <h2 className='text-md font-bold'>Specific Diseases Cover  </h2>
                        <p>Most specific disease waiting periods, which can run up to four years, are waived in GHI policies.  </p></div>
                </div>

                <h1 className=' text-lg md:text-xl font-bold'>Pre Existing Diseases Cover</h1>
                <p>Group health insurance plans typically waive the waiting period for pre-existing diseases (PEDs), covering them from day one. For instance, if an employee with asthma is hospitalized due to a severe attack during smog-filled winters, the GHI will cover the hospitalization costs</p>

                <h1 className=' text-lg md:text-xl font-bold'>Fully Customizable </h1>
                <p>Group Health Insurance policies are tailor-made to meet the employer's specific needs. </p>

                <h1 className=' text-lg md:text-xl font-bold'>Cancellation and Refund Policy</h1>
                <p>Employers can cancel the group health insurance policy by giving prior notice to the insurer. Upon cancellation, they may be eligible for a refund based on the remaining policy period. However, no refund will be issued if any claim is reported while the policy is in force.Explore the comprehensive benefits and secure your employees' health with our Group Health Insurance plans today! Contact us for more details and personalized assistance.  </p>
            </div>

            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>

        


    </>)
}

export default GroupHealthInsurance