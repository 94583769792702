import React from 'react'
import { GiPlainCircle } from 'react-icons/gi'

function PrivacyPolicy() {
  return (
    <div className="">
    <div className="flex flex-col gap-6 bg-[#F4F7FA] justify-center py-20 items-center">
           
           <h1 className='text-center text-[22px] sm:text-4xl font-bold'>Privacy Policy</h1>
           <div className="flex flex-col gap-1 text-[10px] sm:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wider  
           text-center text-[#161C2D]">
               <p>Clearcover: Fast, hassle-free insurance that saves you money. Our privacy policy ensures  </p> 
               <p> your personal data is protected. Get your quote today!</p>
           </div>
    </div>
 
       <div className="py-8 ">
          <div className="max-w-[700px] mx-auto flex flex-col gap-7 text-[14px] px-4">
           <h1 className=' text-lg md:text-xl font-bold'>Introduction </h1>



            <p>Clear Cover Insurance respects the privacy of our business partners and users. This policy explains how we collect, use, share, and safeguard the information you provide during our interactions. By engaging with Clear Cover, you acknowledge that you understand and agree to the terms outlined in this Privacy Policy.</p>
 
           <h1 className=' text-lg md:text-xl font-bold'>Scope of the Policy</h1>
 
           <p>This policy applies to all data collected from our clients, distributors, agents, and employees of client companies. It governs both online and offline data collection, including data shared through our website, portals, and business communications.  </p>
 
           <div className="flex flex-col">
           <h2 className="text-lg font-bold">Types of Information We Collect</h2>
           <ul className="mt-3 space-y-4">
            <p className='font-semibold'>We collect the following types of information: </p>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Business Data:</span> Organization names, registration details, and official contact information.
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Contact and Identification Data:</span>Names, phone numbers, email addresses, and government identification numbers of representatives or insured individuals.   
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Financial and Transactional Data:</span>Payment records, claims history, policy documents, and invoices.
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Website and Portal Data:</span>IP addresses, browser types, cookies, and log files for site performance and analytics.
               </p>
             </li>

             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Communication Data:</span>Emails and messages exchanged between Clear Cover and its business clients.
               </p>
             </li>
           </ul>
           </div>

           <div className="flex flex-col">
           <h2 className="text-lg font-bold">How We Use Your Data</h2>
           <ul className="mt-3 space-y-4">
            <p className='font-semibold'>We process data to: </p>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'> Issue Policies and Manage Claims:</span>Ensure smooth policy issuance, renewal, and claim processing.
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Provide Customer Support:</span>Address inquiries, service requests, and complaints promptly.
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Improve Our Services:</span>Analyze usage patterns to enhance user experience and product offerings.
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Comply with Legal Requirements:</span>Comply with Legal Requirements:
               </p>
             </li>

             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Marketing and Communications:</span>Send product updates, newsletters, and promotional offers (with your consent). 
               </p>
             </li>
           </ul>
           </div>
 
          <h1 className='text-lg md:text-xl font-bold'>Consent and Choice</h1>
          <p>We seek your consent when collecting and processing personal data for non-essential purposes, such as marketing. Users and business representatives may withdraw consent at any time by contacting us.  </p>

          <div className="flex flex-col">
           <h2 className="text-lg font-bold">Sharing of Information </h2>
           <ul className="mt-3 space-y-4">
            <p className='font-semibold'>We may share your data with:   </p>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'> Third-Party Service Providers:</span>Companies offering hosting, IT infrastructure, claims processing, and customer service.   
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Regulatory Authorities and Law Enforcement: </span>As required by law, during audits, or in response to legal requests. 
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Business Partners:</span>Authorized agents or distributors to facilitate business processes.
               </p>
             </li>
           </ul>
           </div>
 
          <p>We ensure that all third parties adhere to data protection regulations through<span className='font-semibold'> Data Processing Agreements (DPA).</span></p>
 
          <h1 className='text-lg md:text-xl font-bold'>International Data Transfers </h1>
          <p>If data is transferred outside of India, we ensure that appropriate safeguards are in place, such as <span className='font-semibold'>Standard Contractual Clauses (SCCs)</span>  or reliance on <span className='font-semibold'>adequacy decisions by regulators.</span></p>

          <h1 className='text-lg md:text-xl font-bold'>Data Retention </h1>
          <p>We retain data only as long as necessary to fulfill the purposes outlined in this policy or as required by law. After the retention period, data is securely deleted or anonymized. </p>

          <h1 className='text-lg md:text-xl font-bold'>Data Security</h1>
          <p>We employ a combination of <span className='font-semibold'>physical, technical, and administrative</span> measures to protect your data from unauthorized access, misuse, or loss</p>

          <div className="flex flex-col">
           {/* <h2 className="text-lg font-bold">Sharing of Information </h2> */}
           <ul className="mt-3 space-y-4">
            <p className='font-semibold'>Our security practices include:</p>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'> Encryption </span>of sensitive data during transmission.    
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Access controls</span>to limit data access to authorized personnel.    
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               Regular <span className='font-semibold'>security audits and vulnerability</span> assessments. 
               </p>
             </li>
           </ul>
           </div>
           <h1 className='text-lg md:text-xl font-bold'>Cookies and Tracking Technologies  </h1>
           <p>Our website uses cookies to optimize user experience and track usage for analytical purposes. You can manage your cookie preferences through browser settings. Disabling cookies may impact certain functionalities of our website.  </p>


           <div className="flex flex-col">
           <h2 className="text-lg font-bold">User Rights </h2>
           <ul className="mt-3 space-y-4">
            <p className='font-semibold'>Clear Cover Insurance respects the rights of individuals and business clients. You have the right to: </p>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Access and Correct Your Data:</span>oRequest a copy of your data and correct any inaccuracies. 
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
               <span className='font-semibold'>Withdraw Consent:</span>Withdraw Consent:   
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
                <span className='font-semibold'>Object to Data Processing:</span> Request the restriction of certain data activities, especially for direct marketing.    
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
                <span className='font-semibold'>Request Deletion:</span> Under certain circumstances, request that we delete personal data. 
               </p>
             </li>
             <li className="flex items-start">
               <span className="bg-black rounded-full mt-2 mr-3">
                 <GiPlainCircle size={5} />
               </span>
               <p className="text-[14px]">
                <span className='font-semibold'>Portability:</span> Obtain your data in a structured format for transfer to another service provider.
               </p>
             </li>
           </ul>
           </div>

        
          <p> To exercise your rights, please email us at <a href="mailto:support@clearcover.in">support@ClearCover.in </a> </p>

          
          <h1 className='text-lg md:text-xl font-bold'>Third-Party Links  </h1>
          <p>Our website and communication portals may contain links to external websites. Clear Cover Insurance is not responsible for the privacy practices or content of these third-party sites. We encourage users to review their privacy policies.   </p>

          
          <h1 className='text-lg md:text-xl font-bold'>Children’s Privacy</h1>
          <p> We do not knowingly collect or process data from individuals under the age of 18. If such data is inadvertently collected, it will be deleted immediately.</p>


          <h1 className='text-lg md:text-xl font-bold'>Changes to This Privacy Policy</h1>
          <p>We may update this policy periodically to reflect changes in our operations or regulatory requirements. We will notify our clients of significant changes via email or by posting an updated policy on our website.</p>

          <h1 className='text-lg md:text-xl font-bold'>Contact Us</h1>
          <p className='font-semibold'>If you have any questions about this policy or wish to exercise your rights, please contact us at:     </p>

          <h1 className='text-lg md:text-xl font-bold'>ClearCover Insurance   </h1>
          <p className='font-semibold'> <span className='font-bold'>Address:</span> <a href="" className='text-blue-500'>1st floor, 2gethr @ ORR TOWER-B, MANTRI COMMERCIO Outer Ring Rd, Bellandur, 560103 </a> </p>

          <p className='font-semibold'> <span className='font-bold'>Phone:</span> 
          <a href="tel:919133309629" className='text-blue-500'> 9133309629 </a> </p>

          <p className='font-semibold'> <span className='font-bold'>Email:</span> <a href="mailto:support@clearcover.in" className='text-blue-500'> support@ClearCover.in</a> </p>






          </div>
       </div>
    </div>
  )
}

export default PrivacyPolicy