import React from 'react'
import {assets} from '../../assets/assets'
import { team_cards } from '../../assets/assets'

function HeroSec() {
  return (
    <div className="py-14 flex flex-col  lg:gap-32">
         <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center px-4 md:mt-10">
                    <div className="flex flex-col  items-center gap-2 md:gap-6">
                        <h1 className='text-[22px] sm:text-5xl font-bold '>Our story</h1>
                        
                        <div className="flex-col gap-0.5 text-[8px] sm:text-lg lg:text-[14px] xl:text-[16px] tracking-wide text-[#161C2D]">
                            <p>With lots of unique blocks, you can easily build a page without  </p>
                            <p>coding. Build your next consultancy website within few minutes.</p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center mt-6">
                        <img src={assets.home_crm_mackbook} alt="" />

                    </div>

                    <div className="flex flex-col md:flex-row items-center gap-6 md:gap-20  justify-around mt-6 ">
                         <div className="flex flex-col items-center text-center md:text-start text-2xl font-bold md:w-[35%] lg:w-[25%]">
                             <h1>Empowering Insurance Solutions for a Better Future</h1>
                         </div>

                         <div className="flex flex-col items-center text-center gap-6 md:text-start justify-center text-[10px] md:text-[14px] tracking-wider leading-6 md:w-[30%]">
                             <p>ClearCover Insurance offers innovative, tech-driven insurance solutions across India, specializing in group and tailored insurance products. With over 100 clients, we prioritize customer satisfaction and simplify the insurance experience.</p>

                             {/* <p>
                             With lots of unique blocks, you can easily build a page without coding. Build your next landing page. With lots of unique blocks, you can easily build a page without coding any other page.</p> */}
                         </div>

                    </div>
         </div>

         {/* #####################   About  us ######################### */}
         <div className="max-w-[90%] md:max-w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-10 lg:gap-20 px-4">

            <div className="flex flex-row gap-4 items-center justify-center">
                <div className="flex flex-col gap-5"> 
                    <img src={assets.home_customer_1} alt="" className='rounded-lg' />
                    <img src={assets.home_customer_4} alt="" className='rounded-lg' />
                </div>

                <div className="flex flex-col gap-4 mt-12">
                    <img src={assets.home_customer_3} alt="" className='rounded-lg'/>
                    <img src={assets.home_customer_2} alt="" className='rounded-lg'/>
                </div>
                
            </div>

             <div className="flex flex-col gap-6 lg:w-[75%] items-center justify-center tracking-wider leading-4 md:leading-6 text-[10px] md:text-[14px] ">
                   <div className="">
                      <p>At ClearCover Insurance, we're committed to making insurance smarter, simpler, and more customer-focused. As a leading insurance broker in India </p>
                   </div>

                   <div className="flex flex-col gap-0.5">
                      <p>we specialize in group insurance, along with a wide range of other insurance products tailored to meet diverse needs. Our mission is to challenge the  </p>
                      <p>traditional insurance model by prioritizing customer satisfaction and delivering exceptional service at competitive rates. </p>
                   </div>

                   <div className="">
                      <p>With over 100 clients across India, we've built our reputation on trust, innovation, and expertise. Our tech-driven approach ensures that our clients have access to the latest tools and resources, making insurance easier to manage and understand.</p>
                   </div>

             </div>
        </div>

        {/* #####################   Security ########################## */}
        <div className="max-w-[90%] md:max-w-[70%] mx-auto grid grid-cols-1 lg:grid-cols-2 justify-center items-center     lg:gap-20 gap-10 px-4">

                <div className="flex flex-col gap-1 items-center justify-center">
                    <h2 className='text-xl font-bold'>ClearCover Insurance: </h2>
                    <h1 className='text-3xl font-bold'>Illuminating Your </h1>
                    <h1 className='text-3xl font-bold'>Path to Security</h1>
                </div>

        <div className="flex flex-col gap-6 lg:gap-20 lg:w-[75%] items-center justify-center tracking-wider leading-4 md:leading-6 text-[10px] md:text-[14px]   ">
                  <div className="flex flex-col gap-2">
                        <div className="flex flex-row  gap-3 items-center">
                            <img src={assets.about_vector_icon} alt="" width="40px" />
                            <span className='text-lg font-bold'>Mission</span>
                        </div>
                        <div className="">
                                <p>At ClearCover Health, we empower with accessible, transparent, and innovative health insurance. We demystify healthcare coverage complexities, providing clarity and peace of mind. Using cutting-edge technology and a commitment to simplicity, we make quality healthcare coverage clear and attainable for all</p>
                        </div>
                  </div>

                  <div className="flex flex-col gap-2">
                        <div className="flex flex-row  gap-3 items-center">
                            <img src={assets.about_vector_icon} alt="" width="40px" />
                            <span className='text-lg font-bold'>Vision</span>
                        </div>
                        <div className="">
                                <p>At ClearCover Health, we envision a future with comprehensive, affordable health insurance for all. We're committed to redefining industry standards, fostering trust, and embracing innovation to shape a healthier, happier, and more secure tomorrow for our members.</p>
                        </div>
                  </div>

             </div>

        </div>


        {/* #####################   TEam  ########################## */} 
         <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center px-4 lg:mt-10">
                   <div className="flex flex-col  items-center gap-4 md:gap-10">
                        <h1 className='text-[22px] sm:text-5xl font-bold '>Meet our team</h1>
                        <div className="flex-col gap-0.5 text-[8px] sm:text-lg lg:text-[14px] xl:text-[18px] tracking-wider text-[#161C2D]">
                            <p>WWith lots of unique blocks, you can easily build a page without</p>
                            <p>coding. Build your next consultancy website within few minutes.</p>
                        </div>
                    </div>

                    <div className="flex flex-row items-center">
                     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center mt-8 md:mt-14">
                        {team_cards.map((item, index) => (
                            <div key={index} className="w-[200px] shadow-sm">
                            <img  src={item.image} alt="" className=' ' />
                            <div className="flex flex-col gap-2 p-3">
                                <p className='text-[12px] font-bold'>{item.title}</p>
                                <span className='text-[10px] font-thin'>{item.content}</span>
                            </div>
                            </div>
                        ))}
                        <div className=" flex flex-col justify-center items-center">
                               <p className='text-[14px] font-bold'>Interested to join 
                               our team ?</p>
                               <div className="flex flex-row items-center gap-3 text-[#473BF0]">
                                   <span className='text-lg font-bold'>Apply now</span>
                                   <img src={assets.arrow_right_blue} alt="" />
                               </div>
                        </div>
                      </div>
                    </div>
            </div>

        
    </div>
  )
}

export default HeroSec