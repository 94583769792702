import React from 'react'

function FullStackDeveloper() {
  return (
    <div className="py-32 ">
         <div className="max-w-[700px] mx-auto flex flex-col gap-10 text-[14px] px-4">

             <div className="flex flex-col items-center gap-3">
                <h1 className='text-center text-[22px] sm:text-4xl font-bold'>Full Stack Developer</h1>
                <span className='text-lg font-thin'>Full Time, Remote</span>
             </div>

            <p>Headquartered in Melbourne, Australia, Envato is a completely online company with an ecosystem of sites and services to help people get creative. We’ve consistently been named as one of the Best Places to Work in Australia, since 2015, in the BRW Awards, and we’ve also been awarded the title of Australia's Coolest Company for Women and Diversity by JobAdvisor.</p>

            <p>Envato was found in 2006 and, since then, we’ve helped a community of creative sellers earn more than $500 Million. Millions of people around the world choose our marketplace, studio and courses to buy files, hire freelancers, or learn the skills needed to build websites, videos, apps, graphics and more. Find out more at Envato Market, Envato Elements, Envato Sites, Envato Studio and Tuts+.</p>

            <p>You might be surprised to know that not only do we run some of the biggest websites in the world; we’re also growing really fast! We have close to 600 staff and contractors worldwide, adding more than 100 people to the business, year on year, since 2017.</p> 

            <h1 className=' text-lg md:text-xl font-bold'>What you’ll be doing</h1>

            <p>A Senior Data Engineer provides technical and delivery leadership for a team of developers working on data integration and processing projects. This role will work with stakeholders and other developers to design and implement technical data solutions for the business in a way that balances quality, cost, time and maintainability.</p>

            <h1 className=' text-lg md:text-xl font-bold'>Skills & Qualifications</h1>
            <ul className='list-disc list-inside flex flex-col gap-1 text-[10px] sm:text-[14px]'>
                <li>Business Intelligence solutions</li>
                <li>Cloud computing platforms (AWS)</li>
                <li>Relevant server-side programming languages (C# .NET)</li>
                <li>SQL and NoSQL database solutions (AWS Redshift and AWS DynamoDB)</li>
                <li>Big data processing frameworks and concepts </li>
                <li>DevOps practices</li>
                <li>Serverless Architecture</li>
                <li>*NIX operating systems</li>
                <li>Demonstrates ability to communicate effectively with the rest of the team</li>
            </ul>

            <h1 className=' text-lg md:text-xl font-bold'>How to apply</h1>

            <p>A Senior Data Engineer provides technical and delivery leadership for a team of developers working on data integration and processing projects. This role will work with stakeholders and other developers to design and implement technical data solutions for the business in a way that balances quality, cost, time and maintainability.</p>

            <div className="">
               <button className='bg-[#473BF0] inline-flex px-5 py-3.5 rounded-lg text-[#fff] font-bold'>Apply for this job</button>
            </div>
            
         </div>
            
    </div>
  )
}

export default FullStackDeveloper