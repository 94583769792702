import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function WorkmanCompensationInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Workmen Compensation Insurance </h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Workmen's Compensation Insurance?  </h1>
        <p>Workmen's Compensation Insurance provides coverage for employees against work-related injuries and illnesses. This policy ensures that employees receive financial support and medical care in case of workplace accidents, promoting a safe and secure working environment.  </p>


        <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Employee Protection:</li>
                <p>Covers legal fees and settlements. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Legal Compliance:</li>
                <p> Meets statutory requirements for worker compensation. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Business Continuity: </li>
                <p>Helps maintain workforce morale and productivity. </p>
            </ul>

        </div>

        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Medical Expenses:</li>
                <p>Coverage for medical treatment costs. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Disability Benefits:</li>
                <p>  Compensation for temporary or permanent disability. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Death Benefits:</li>
                <p>Financial support for the family in case of employee death.</p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Rehabilitation Costs:</li>
                <p>Coverage for rehabilitation and retraining expenses. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Legal Fees:</li>
                <p> Covers legal costs associated with worker compensation claims.</p>
            </ul>
        </div>
    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default WorkmanCompensationInsurance