import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function FireInsurance() {
    return (<>
        <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Fire & Burglary Insurance </h1>
            </div>
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>What is Fire & Burglary Insurance?  </h1>
                <p> Fire & Burglary Insurance is a policy designed to protect your business property against losses or damages caused by fire, theft, or burglary. This insurance covers the building, its contents, stock, and machinery, providing financial security and peace of mind.   </p>


                <h1 className=' text-lg md:text-xl font-bold'>Benefits of Fire & Burglary Insurance :</h1>

                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Comprehensive Coverage:</li>
                        <p>Protects against fire, theft, and burglary.</p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Business Continuity: </li>
                        <p> Ensures your business can recover quickly after an incident. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Financial Security: </li>
                        <p> Covers repair and replacement costs. </p>
                    </ul>

                </div>

                <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Fire Damage:</li>
                        <p>  Compensation for damage caused by fire and allied perils.</p>
                    </ul>
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Theft/Burglary: </li>
                        <p>Coverage for losses due to theft or burglary.  </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Stock and Inventory:</li>
                        <p> Protection for your business stock and inventory. </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Machinery and Equipment:</li>
                        <p> Coverage for damage to machinery and equipment. </p>
                    </ul>
                </div>



            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
       
   </> )
}

export default FireInsurance