// import React, {useState} from 'react'
// import {Link} from 'react-router-dom'
// import { assets } from '../assets/assets'
// import { CgMenuRightAlt } from "react-icons/cg";
// import { RxCross2 } from "react-icons/rx";

// function Navbar() {
//   const[openMenubar , setOpenMenuBar]= useState(false)
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleDropdown = () => setIsOpen(!isOpen);

//   const handleBarMenu=()=>{
//     setOpenMenuBar(!openMenubar)
//   }

//   return (
//        <div className="py-4 shadow-xl shadow-green-50 lg:fixed bg-[#fff] z-10">
//             <div className="max-w-[80%]  mx-auto flex flex-row items-center justify-between  px-4">
//                  <ul className="min-w-[130px]  max-w-[12%]">
//                    <li><Link to="/"><img src={assets.logo} alt=""  /></Link></li>
//                  </ul>

//                  <div className={`absolute left-0 top-[8.1%] duration-500 ${!openMenubar ? `left-[-100%] lg:left-auto `:''} bg-white  lg:bg-[#fff] w-[60%] lg:w-[57%] h-full lg:relative`}>
//                    <ul className='flex flex-col lg:flex-row items-center py-5 lg:py-0 gap-6 lg:gap-10 text-[16px] font-semibold'>
//                    <li><Link to="/">Home</Link></li>
//                     <li><Link to="/about">About us </Link></li>
//                     <li><Link to="/insurance">Insurance</Link></li>
//                     <li><Link to="/contact">Contact us</Link></li>
//                     {/* <li><Link to="/login">Login</Link></li> */}
//                     {/* <li className="relative group">
//                           <button className="hover:text-blue-500">Login</button>
//                           <div className="absolute w-[200px] right-[-100%] md:right-0 left-0 hidden mt-2 text-center space-y-4 bg-white border border-gray-200 shadow-lg group-hover:block
//                           transition-all duration-1000 ease-in-out">
//                                <a href="https://corphelp.clearcover.in/login" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Employee</a>
//                                 <a href="https://corphelp.clearcover.in/hr_login/login" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">HR Login</a>
//                                 <a href="https://corphelp.clearcover.in/crm-pages" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">CRM Login</a>
//                           </div>
//                     </li> */}
//      <li className="relative">
//       <button
//         onClick={toggleDropdown}
//         className="hover:text-blue-500"
//       >
//         Login
//       </button>
//       {isOpen && (
//         <div
//           className="absolute left-[-70%] w-[100px] mt-9 text-center transition-all duration-500 ease-in-out"
//           onClick={() => setIsOpen(false)} // Hide the dropdown when clicking inside it
//         >
//           <a href="https://corphelp.clearcover.in/login" target='_blank' className="block py-1  text-[13px] rounded-full text-gray-700 hover:bg-green-100">Employee</a>
//           <a href="https://corphelp.clearcover.in/hr_login/login" target='_blank' className="block py-1 mt-1 text-[13px] rounded-full text-gray-700 hover:bg-green-100">HR Login</a>
//           <a href="https://corphelp.clearcover.in/crm-pages" target='_blank' className="block  py-1 mt-1 text-[13px] rounded-full text-gray-700 hover:bg-green-100">CRM Login</a>
//         </div>
//       )}
//     </li>
//                     <div className="bg-[#61BC6D] block w-[80%] text-center lg:hidden text-[#fff] text-[14px] px-6 py-3 rounded-xl shadow-lg hover:shadow-green-100 font-semibold">
//                        <button><Link to="/contact">Contact us</Link></button>
//                    </div>
//                    </ul>
//                  </div>

//                  <div className="bg-[#61BC6D] hidden lg:block text-[#fff] text-[14px] px-6 py-3 rounded-xl shadow-lg hover:shadow-green-100 font-semibold">
//                  <button><Link to="/contact">Contact us</Link></button>
//                  </div>

//                  <div className="block lg:hidden">
//                   {openMenubar? <RxCross2 onClick={handleBarMenu}
//                                     size={30} className='text-[#61BC6D] font-extrabold'/>
//                               :<CgMenuRightAlt onClick={handleBarMenu}
//                                     size={30} className='text-[#61BC6D] font-extrabold'/> }
//                  </div>
//             </div>
//        </div>
//   )
// }

// export default Navbar
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { assets } from '../assets/assets';
import { CgMenuRightAlt } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";

function Navbar() {
  const [openMenubar, setOpenMenuBar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

    const dropdownRef = useRef(null);
  
    const toggleDropdown = () => {
      setIsOpen(prev => !prev);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      // Add event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Clean up the event listener
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);


  const handleBarMenu = () => {
    setOpenMenuBar(!openMenubar);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`py-4 shadow-xl shadow-green-50 bg-[#fff] z-10 ${isScrolled ? 'fixed top-0 left-0 w-full transition-all duration-500' : 'relative'}`}>
    {/* // <div className={`py-4 shadow-xl shadow-green-50 bg-[#fff] z-10 fixed top-0 left-0 w-full transition-all duration-500`}> */}
      <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-row items-center justify-between px-4">
        <ul className="min-w-[130px] max-w-[12%]">
          <li><Link to="/"><img src={assets.logo} alt="logo" /></Link></li>
        </ul>

        <div className={`absolute left-0 top-[89%] duration-500 ${!openMenubar ? 'left-[-100%] lg:left-auto' : ''}  lg:bg-[#fff] w-[60%] lg:w-[57%]  lg:relative`}>
          <ul className='flex flex-col lg:flex-row items-center py-5 lg:py-0 gap-6 lg:gap-10 text-[16px] bg-[#fff] h-[100vh] lg:h-auto font-semibold'>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/insurance">Products</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>

            <li className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="hover:text-blue-500"
      >
        Login
      </button>
      {isOpen && (
        <div
          className="absolute left-[-70%] w-[130px] mt-7 flex flex-col items-center rounded-lg shadow-xl text-center transition-all duration-1000 bg-white py-2 ease-in-out"
          onClick={() => setIsOpen(false)} 
        >
          <a href="https://corphelp.clearcover.in/login" target='_blank' rel="noopener noreferrer" className="block py-1 text-[13px] w-[100px] rounded-full text-gray-700 hover:bg-green-100">Employee</a>
          <a href="https://corphelp.clearcover.in/hr_login/login" target='_blank' rel="noopener noreferrer" className="block py-1 mt-1 text-[13px] w-[100px] rounded-full text-gray-700 hover:bg-green-100">HR Login</a>
          <a href="https://corphelp.clearcover.in/crm-pages" target='_blank' rel="noopener noreferrer" className="block py-1 mt-1 text-[13px] w-[100px] rounded-full text-gray-700 hover:bg-green-100">CRM Login</a>
        </div>
      )}
    </li>

            <div className="bg-[#61BC6D] block w-[80%] text-center lg:hidden text-[#fff] text-[14px] px-6 py-3 rounded-xl shadow-lg hover:shadow-green-100 font-semibold">
              {/* <button><Link to="/contact">Contact us</Link></button> */}
              <button><Link to="/claimintimation">Claim Ɪntimation</Link></button>
            </div>
          </ul>
        </div>

        <div className="bg-[#61BC6D] hidden lg:block text-[#fff] text-[14px] px-4 py-3 rounded-xl shadow-lg hover:shadow-green-100 font-semibold">
          {/* <button><Link to="/contact">Contact us</Link></button> */}
          <button><a href="/claimintimation">Claim Ɪntimation</a></button>
        </div>

        <div className="block lg:hidden">
          {openMenubar
            ? <RxCross2 onClick={handleBarMenu} size={30} className='text-[#61BC6D] font-extrabold' />
            : <CgMenuRightAlt onClick={handleBarMenu} size={30} className='text-[#61BC6D] font-extrabold' />}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
