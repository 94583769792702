import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function AviationInsurance() {
  return (
    <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Aviation Insurance   </h1>
            </div>
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>What is Aviation Insurance?   </h1>
                <p>Aviation Insurance covers the unique risks of aviation operations, including aircraft damage, passenger injuries, and third-party liabilities. It ensures comprehensive protection for aviation businesses and aircraft operators.   </p>


                <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Comprehensive Coverage: </li>
                        <p>Protects against a wide range of aviation-related risks.</p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Financial Protection: </li>
                        <p> Covers high-cost damages and liabilities. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Safety Assurance: </li>
                        <p> Enhances operational safety and compliance with regulations. </p>
                    </ul>

                </div>

                <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
                <div className="flex flex-col gap-4">
                    <ul className='flex flex-col gap-1'>

                        <li className='text-md font-bold'>Aircraft Hull Coverage:</li>
                        <p> Protection for physical damage to the aircraft. </p>
                    </ul>
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Passenger Liability:</li>
                        <p>Coverage for injuries to passengers. </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Third-Party Liability:</li>
                        <p>Protects against liabilities to third parties on the ground. </p>
                    </ul>

                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>Cargo Insurance:</li>
                        <p>Coverage for goods transported by the aircraft.</p>
                    </ul>
                    <ul className='flex flex-col gap-1'>
                        <li className='text-md font-bold'>War Risk Coverage: </li>
                        <p> Protection against risks due to war, hijacking, and terrorism. </p>
                    </ul>
                </div>
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
  )
}

export default AviationInsurance