import logo from './LOGO-CC.png'
import up_arrow from './up-arrow.png'
import up_arrow1 from './up-arrow 1.png'
import up_arrow2 from './up-arrow2.png'
import hero_img from './connect-google.png';
import home_gif from './home-gif.gif'
import home_customer_1 from './home-customer-1.jpg'
import home_customer_2 from './home-customer-2.jpg'
import home_customer_3 from './home-customer-3.jpg'
import home_customer_4 from './home-customer-4.jpg'
import right_arrow from './tail-right.png';
import home_crm_mackbook_img from './home-crm-mackbook.png'
import dot_1 from './dots1.png'
import home_experience from './home-experience.png'
import home_choose_dots from './home-choose-dots.png'
import home_crm_mackbook from './home_crm_mackbook.png'
import home_crm_rectangle from './home-crm-Rectangle.png'
import home_employee_macbook from './home_employee_mackbook.png'
import home_question from './home-question.png'
import chat from './Chat.png'
// import home_gif from './home_gif.gif'
import home_employee from './home_employee.png'
import brand_happi from './brand-happie-mobiles.png'
import brand_hcl from './brand-hcl.png'
import brand_taj from './brand-taj.png'
import brand_techno from './brand-techno-bind.png'
import brand_trinity from './brand-trinity.png'

import visitor_bg from './BG.png';
import button_play from './button-play.png';
import oval from './Oval.png';
import dots from './Dots.png';

import home_visitor_1 from './home-visitor-01.png'

import aboust_us_icon from './aboutusicon.png'

import right_tick from './right_tick.png'
import man_standing from './man standing.png'
import man_bg_oval from './man-bg-oval.png' 

import tail_right_white from './tail-right-white.png'

import blog_img1 from './blog-img1.png'
import blog_img2 from './blog-img2.png'
import blog_img3 from './blog-img3.png'

import user_male_img_1 from './user-male1.png'
import user_male_img_2 from './user-male2.png'
import user_male_img_3 from './user-male3.png'
import user_male_img_4 from './user-male4.png'
import user_male_img_5 from './user-male5.png'
import user_male_img_6 from './user-male6.png'
import user_female_img_1 from './user-female1.png'
import user_female_img_2 from './user-female2.png'

// import mackbook_img from './about-laptop.png'
import about_mackbook_img from './about-mackbook.png'
import about_img_1 from './about-img1.png'
import about_img_2 from './about-img2.png'
import about_img_3 from './about-img3.png'
import about_img_4 from './about-img4.png'
import about_vector_icon from './about-vector.png'
import about_person from './about-person.png'
import about_buseness from './about-business.png'
import about_company from './about-company.jpg'
import about_company1 from './about-company1.avif'
import about_company3 from './about-company3.jpg'
import about_why from './about-why.png'
import person_1 from './p-1.png'
import person_2 from './p-2.png'
import person_3 from './p-3.png'
import person_4 from './p-4.png'
import person_5 from './p-5.png'
import person_6 from './p-6.jpg'
import person_7 from './p-7.png'
import arrow_right_blue from './tail-right-blue.png'
import team_spupport from './team-support.png'
import team_danish from './team-danish.jpg'
import team_abhisekh from './team-abhisekh.jpg'
import team_group from './team-group.jpg'
import team_harish from './team-harish.jpg'
import team_katha from './team-katha.jpg'
import team_mdh1 from './team-mdh1.jpg'
import team_mdh2 from './team-mdh2.jpg'
import team_mdh3 from './team-mdh3.jpg'
import team_pavan_suresh from './team-pavan.jpg'
import team_rohit from './team-rohit.jpg'
import team_suchithra from './team-suchithra.jpg'
import team_about_who from './team-who-are-we.jpg'
import team_career_group from './team-career-group.jpg'
import team_surbhi from './team-surbhi.jpg'

import career_video from './career-video.png'
import career_icon_1 from './career-icon-1.png'
import career_icon_2 from './career-icon-2.png'
import career_icon_4 from './career-icon-4.png'
import career_icon_5 from './career-icon-5.png'
import career_icon_6 from './career-icon-6.png'
import insurance_icon_1 from './insurance-icon-01.png'
import insurance_icon_2 from './insurance-icon-02.png'
import insurance_icon_3 from './insurance-icon-03.png'
import insurance_icon_4 from './insurance-icon-04.png'
import insurance_icon_5 from './insurance-icon-05.png'
import insurance_icon_6 from './insurance-icon-06.png'
import insurance_icon_7 from './insurance-icon-07.png'
import insurance_icon_8 from './insurance-icon-08.png'
import insurance_icon_9 from './insurance-icon-09.png'
import insurance_icon_10 from './insurance-icon-10.png'
import insurance_icon_11 from './insurance-icon-11.png'
import insurance_icon_12 from './insurance-icon-12.png'
import insurance_icon_13 from './insurance-icon-13.png'

import blog_networking from './blog-networking.png'
import blog_hospital_building from './blog-hospital-building.png'
import blog_table from './blog-table.png'
import blog_network from './blog-network.png'
import blog_ghi from './blog-ghi.png'



import footer_logo from './footer-logo.png'




export const assets={
    up_arrow,
    up_arrow1,
    up_arrow2,
    hero_img,
    home_gif,
    home_customer_1,
    home_customer_2,
    home_customer_3,
    home_customer_4,
    home_crm_mackbook_img,
    dot_1,
    home_experience,
    home_choose_dots,
    home_crm_mackbook,
    home_crm_rectangle,
    home_employee_macbook,
    home_employee,
    home_question,
    chat,
    right_arrow,
    visitor_bg,
    button_play,
    oval,
    dots,
    home_visitor_1,
    aboust_us_icon,
    right_tick,
    man_standing,
    man_bg_oval,
    tail_right_white,
    about_mackbook_img,
    about_img_1,
    about_img_2,
    about_img_3,
    about_img_4,
    about_vector_icon,
    about_person,
    about_buseness,
    about_company,
    about_company1,
    about_company3,
    arrow_right_blue,
    team_spupport,
    team_about_who,
    team_career_group,
    about_why,
    career_video,
    team_group,
    logo,
    insurance_icon_1,
    blog_networking,
    blog_hospital_building,
    blog_table,
    blog_ghi,
    footer_logo,
    


}

// export  const insurance_cards = [
//     { id: 1, image:insurance_icon_1, title: 'Group Health Insurance', content: 'Get a Quote' },
//     { id: 2, image:insurance_icon_2, title: 'Fire & Burglary Insurance', content: 'Get a Quote' },
//     { id: 3, image:insurance_icon_3, title: 'Office Package Policy', content: 'Get a Quote' },
//     { id: 4, image:insurance_icon_4, title: 'Group Accidental Insurance', content: 'Get a Quote' },
//     { id: 5, image:insurance_icon_5, title: 'General Liability Insurance', content: 'Get a Quote' },
//     { id: 6, image:insurance_icon_6, title: 'Aviation Insurance', content: 'Get a Quote' },
//     { id: 7, image:insurance_icon_7, title: 'Construction Insurance', content: 'Get a Quote' },
//     { id: 8, image:insurance_icon_8, title: 'Marine Insurance', content: 'Get a Quote' },
//     { id: 9, image:insurance_icon_9, title: 'Cyber Security Insurance', content: 'Get a Quote' },
//     { id: 10, image:insurance_icon_10, title: "Director's & Officers Liability Insurance", content: 'Get a Quote' },
//     { id: 11, image:insurance_icon_11, title: 'Plant & Machinery Insurance', content: 'Get a Quote' },
//     { id: 12, image:insurance_icon_12, title: 'Professional Indemnity Insurance', content: 'Get a Quote' },
//     { id: 13, image:insurance_icon_13, title: 'Workmen Compensation Insurance', content: 'Get a Quote' },
//   ];

  export const insurance_cards = [
    { id: 1, image: insurance_icon_1, title: 'Group Health Insurance', content: 'Get a Quote', path: '/grouphealth' },
    { id: 2, image: insurance_icon_2, title: 'Fire & Burglary Insurance', content: 'Get a Quote', path: '/fire' },
    { id: 3, image: insurance_icon_3, title: 'Office Package Policy', content: 'Get a Quote', path: '/office' },
    { id: 4, image: insurance_icon_4, title: 'Group Accidental Insurance', content: 'Get a Quote', path: '/groupaccidental' },
    { id: 5, image: insurance_icon_5, title: 'General Liability Insurance', content: 'Get a Quote', path: '/generalliability' },
    { id: 6, image: insurance_icon_6, title: 'Aviation Insurance', content: 'Get a Quote', path: '/aviation' },
    { id: 7, image: insurance_icon_7, title: 'Construction Insurance', content: 'Get a Quote', path: '/construction' },
    { id: 8, image: insurance_icon_8, title: 'Marine Insurance', content: 'Get a Quote', path: '/marine' },
    { id: 9, image: insurance_icon_9, title: 'Cyber Security Insurance', content: 'Get a Quote', path: '/cybersecurity' },
    // { id: 10, image: insurance_icon_10, title: "Director's & Officers Liability Insurance", content: 'Get a Quote', path: '/directors' },
    { id: 11, image: insurance_icon_11, title: 'Plant & Machinery Insurance', content: 'Get a Quote', path: '/plant' },
    { id: 12, image: insurance_icon_12, title: 'Professional Indemnity Insurance', content: 'Get a Quote', path: '/professional' },
    { id: 13, image: insurance_icon_13, title: 'Workmen Compensation Insurance', content: 'Get a Quote', path: '/workman' },
  ];
  

export const aboutusslider_cards = [
    {
      "image": user_male_img_1,
      "name": "Mr.Ajey",
      "company": "Director-HR",
      "review": "“Periodic updates on the claims right from the beginning till the end. No need to send reminders to know the status. Happy to see this customer-centric approach.”",
      "stars": 5
    },
    {
      "image": user_female_img_1,
      "name": "Ms.Sneha",
      "company": "HR Manager",
      "review": "“During Covid times we were so panicked about looking for beds in the hospital, thanks to MDH Team to provide us support whenever we needed it. All the Best !”",
      "stars": 4
    },
    {
      "image": user_male_img_2,
      "name": "Mr.Manjunath",
      "company": "Head HR",
      "review": "“Access to information is amazing, no need to be dependent on point of contact unless you need which you don't have at your fingertip.Thanks to Mr.Shiva .”",
      "stars": 4
    },
    {
      "image": user_female_img_2,
      "name": "Ms.Raina",
      "company": "HR Manager",
      "review": "“Fantastic service. The team's guidance and support were very helpful. Thank you for being there for us during challenging times. Your dedication Thanks”",
      "stars": 5
    },
    {
      "image": user_male_img_6,
      "name": "Mr.Kabir",
      "company": "Finance Director",
      "review":"“Outstanding customer service. The MDH Team's guidance and support throughout the process were exceptional. Thank you for being there for us during challenging”",
      "stars": 4
    },  
    {
      "image": user_male_img_3,
      "name": "Mr.Seraj",
      "company": "“Director-HR",
      "review": "We have been with MDH for the last 5 years, never seen any difference between commitment and delivery.Thank you for Your dedication and professionalism.”",
      "stars": 5
    },
    {
      "image": user_male_img_4,
      "name": "Mr.Karan",
      "company": "HR Manager",
      "review": "“Top-notch service from the team. Their guidance and support throughout were remarkable. Thank you for your assistance during trying times.”",
      "stars": 5
    },
    {
      "image": user_male_img_5,
      "name": "Mr.Ranjan",
      "company": "Director-HR",
      "review": "“Excellent customer service. The team was supportive and guided us through the entire process. Thanks for your help during difficult periods.”",
      "stars": 4
    },
  ]
 
//   const carousal_data2 = [
//     {
//       "image": aboust_us_icon,
//       "name": "Mr.Nandan",
//       "company": "Human Resources Director",
//       "review": "“I have received excellent services from MDH. My claim was settled in just few days. Thank You ”",
//       "stars": 5
//     },
//     {
//       "image": aboust_us_icon,
//       "name": "Ms.Khusi",
//       "company": "Chief HR Officer",
//       "review": "“Outstanding service. The team provided invaluable guidance and support. Thank you for being with us through difficult periods. Your professionalism and dedication were remarkable ”",
//       "stars": 4
//     },
//     {
//       "image": aboust_us_icon,
//       "name": "Mr.Kunal",
//       "company": "HR Lead",
//       "review": "“My claims were settled smoothly thanks to the very supportive team at MDH Happy with the services.",
//       "stars": 4
//     },
//     {
//       "image": aboust_us_icon,
//       "name": "Ms.Asif",
//       "company": "Head of Human Resources",
//       "review": "“Superb service. The team's support and guidance were instrumental during challenging times. Thank you for your assistance. Your hard work and dedication were evident throughout the process.”",
//       "stars": 5
//     },
//     {
//       "image": aboust_us_icon,
//       "name": "Ms.Maheshwari",
//       "company": "Finance Director",
//       "review":"“Outstanding customer service. The MDH Team's guidance and support throughout the process were exceptional. Thank you for being there for us during challenging times. Keep up the excellent work!”",
//       "stars": 4
//     },  
//     {
//       "image": aboust_us_icon,
//       "name": "Mr.Vishal",
//       "company": "HR Coordinator",
//       "review": "“Our experience with MDH over the past 5 years has shown their dedication to fulfilling their commitments, ensuring a reliable and consistent service.”",
//       "stars": 4
//     },
//   ]
  

// export const aboutusslider_cards = [
//     { id: 1, image:aboust_us_icon, content: "“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”" ,name:"Curtis Rhodes",title:"Digital Marketer"},
//     { id: 2, image:aboust_us_icon, content: "“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”" ,name:"Curtis Rhodes",title:"Digital Marketer"},
//     { id: 3, image:aboust_us_icon, content: "“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”" ,name:"Curtis Rhodes",title:"Digital Marketer" },
//     { id: 4, image:aboust_us_icon, content: "“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”" ,name:"Curtis Rhodes",title:"Digital Marketer"},
//     { id: 5, image:aboust_us_icon, content: "“Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.”" ,name:"Curtis Rhodes",title:"Digital Marketer"},
//   ];

// export const blog_cards =[
//     {id :1, image:blog_img1, title:"Career", content:"How to win any job you want Get started with 5 steps."},
//     {id :1, image:blog_img2, title:"Lifestyle", content:"10 ways to reduce your office work depression."},
//     {id :1, image:blog_img3, title:"Career", content:"Why should you work as a team even on small projects."}
// ]
 

export const blog_cards = [
  {
      id: 1,
      image: blog_network,
      title: "Network V/S Non Network Hospital<",
      content: "Understand how network hospitals can reduce your costs and simplify billing,",
      path:"/firstBLog"
  },
  {
      id: 2,
      image: blog_ghi,
      title: "Group Health Insurance",
      content: "Enhance employee well-being and reduce healthcare costs with comprehensive group health insurance",
      path:"/bloggrouphealthinsurance"
  },
  // {
  //     id: 3,
  //     image: blog_img3,
  //     title: "Team Collaboration",
  //     content: "Learn the benefits of teamwork and how collaborating effectively can drive success in every project."
  // }
];


export const team_cards =[
    {id:1, image:team_abhisekh , title:"Joe Bridges" , content:"Founder"},
    {id:2, image:team_harish , title:"Jeffrey Walters" , content:"Cheif Executive Officer"},
    {id:3, image:team_katha , title:"Jason Reed" , content:"Chief Technology Officer"},
    {id:4, image:team_suchithra , title:"Nellie Padilla" , content:"Creative Director"},
    {id:5, image:team_mdh1 , title:"Dean Bell" , content:"Lead Programmer"},
    {id:6, image:team_rohit , title:"Pearl Brooks" , content:"Digital Marketer"},
    {id:7, image:team_mdh3 , title:"Isaiah Griffin" , content:"UX Designer"},
    {id:8, image:team_danish , title:"Isaiah Griffin" , content:"UX Designer"},
    {id:9, image:team_pavan_suresh , title:"Isaiah Griffin" , content:"UX Designer"},
    {id:10, image:team_mdh2 , title:"Isaiah Griffin" , content:"UX Designer"},
    {id:10, image:team_surbhi , title:"Surbhi" , content:"Brand Manager"}, 
]

export const career_Card = [
  {
      id: 1,
      icon: career_icon_1,
      title: "Personal Growth",
      content: "At Clearcover, we invest in your professional development, offering learning opportunities and career advancement within a dynamic insurance environment.",
  },
  {
      id: 2,
      icon: career_icon_2,
      title: "Competitive Salary",
      content: "We offer industry-leading compensation packages that reflect your skills and contributions, ensuring you're rewarded for the value you bring.",
  },
  {
      id: 3,
      icon: career_icon_5,
      title: "Casual Dress Code",
      content: "Our relaxed dress code lets you feel comfortable while you focus on making an impact, without the need for formal business attire.",
  },
  {
      id: 4,
      icon: career_icon_4,
      title: "Unlimited Time Off",
      content: "We offer flexible, unlimited paid time off so you can maintain work-life balance and recharge when needed.",
  },
  {
      id: 5,
      icon: career_icon_5,
      title: "Yearly Bonuses",
      content: "Enjoy yearly performance bonuses as recognition for your hard work and dedication to our company’s mission.",
  },
  {
      id: 6,
      icon: career_icon_6,
      title: "Global Community",
      content: "Join a diverse global team that fosters collaboration, inclusion, and shared success across different cultures and regions.",
  }
];

export const jobOpening_cards=[
    // {id:1, role:'General Manager (Non-EB Sales)', location:'Bangalore',department:"SMB Sales" ,experience:"1 - 3 years", opening:"1", path:"/generalmanager", view:"View Job"},
    {id:1, role:'Placement Associate (Liability)', location:'Bangalore',department:"Sales" ,experience:"3 - 5 years", opening:"1", path:"/placementassociate", view:"View Job"},
    // {id:1, role:'Wellness Operation Intern', location:'Bangalore',department:"Customer Happiness" ,experience:"0 - 0 years", opening:"1", path:"/wellnessintern",  view:"View Job"},
    // {id:1, role:'SDR - Team Lead', location:'Bangalore',department:"Business Development Lead" ,experience:"3 - 8 years", opening:"1", path:"/sdrlead",  view:"View Job"},
    // {id:1, role:'Relationship Manager (Operations)', location:'Bangalore',department:"Endorsement Operations" ,experience:"2 - 4 years", opening:"1", path:"/relationshipmanager",  view:"View Job"},
    // {id:1, role:'Business Analyst', location:'Bangalore',department:"Customer Happiness" ,experience:"1 - 3 years", opening:"1", path:"/businessanalyst", view:"View Job"},
    // {id:1, role:'Customer Support Associate', location:'Bangalore',department:"Customer Happiness" ,experience:"0 - 2 years", opening:"1", path:"/customersupport", view:"View Job"},
]

export const brand_slider=[
   {id:1, image:brand_happi},
   {id:2, image:brand_hcl},
   {id:3, image:brand_taj},
   {id:4, image:brand_techno},
   {id:5, image:brand_trinity},
]

export const branch_contact=[
  {
    id:1,
    state:"Karnataka (Mangalore)",
    branch_manager:"Sandeep",
    adress:"ESSEL WILLCON, #10, 2ND FLOOR, BENDOORWELL CIRCLE, MANGALORE- 575002", 
    location:"https://maps.app.goo.gl/y5A8aoEPEoiNAoVP8",
    phoone:"",
    email:"",
  },
  {
    id:2,
    state:"Banglore (Hebbal)",
    branch_manager:"Ammu P",
    adress:"Sri Sheshadri Sampadha Bldng, # 92/1 & 2, 3rd Floor, Hebbal ring road, Bengaluru – 560024",
    location:"https://maps.app.goo.gl/wBxqjiQFHkNK9KMMA",
    phoone:"",
    email:"",
  },
  {
    id:3,
    state:"Banglore (Indiranagar)",
    branch_manager:"Suchithra Naidu ",
    adress:"No. 648/A-3, 1st Floor, OM Chambers, Binnamangala, 1st Stage, Indiranagar Bangalore – 560038",
    location:"https://maps.app.goo.gl/8owYKwxDny938Qqu8",
    phoone:"",
    email:"",
  },
  {
    id:4,
    state:"Maharashtra (Pune)",
    branch_manager:"Vinod Kumar K B",
    adress:"3rd Floor, Office no 301, Classic House, Senapati Bapat Rd, above ICICI Bank, Shivaji Co operative Housing Society, Gokhalenagar, Pune - 411016",
    location:"https://maps.app.goo.gl/zdK2XzDKv3Kr9rCZ9",
    phoone:"",
    email:"",
  },
  {
    id:5,
    state:" Chennai (Sowcarpet)",
    branch_manager:"R. Pradheep",
    adress:"No.130, Dhanalakshmi complex, 3rd Floor, NSC Bose Road, Sowcarpet, Chennai-600079",
    location:"https://maps.app.goo.gl/aZZmDgbrsWpuMgsu8",
    phoone:"",
    email:"",
  },
  {
    id:6,
    state:"Tamilnadu (SALEM)",
    branch_manager:"Sathish Kumar K",
    adress:"NO.3/1, JAWAHAR EMPORIUM, 4th FLOOR, ROYAL GARDEN, MEYYANUR, Opp to ARRS MULTIPLEX, SALEM- 636004.",
    location:"https://maps.app.goo.gl/aZZmDgbrsWpuMgsu8",
    phoone:"",
    email:"",
  },
  {
    id:7,
    state:"Tamilnadu (Coimbatore)",
    branch_manager:"Sathish Kumar K",
    adress:"3rd floor, city center, 14/15 E arokiasamy road, R.S. puram coimbatore, Tamil nadu- 641002",
    location:"https://maps.app.goo.gl/aZZmDgbrsWpuMgsu8",
    phoone:"",
    email:"",
  },
  {
    id:8,
    state:"Tamilnadu (Karur)",
    branch_manager:"Sathish Kumar K",
    adress:"No. 46, Ground Floor, Thiru-Vi-Ka Road, Yesbank Building, Karur - 639001",
    location:"https://maps.app.goo.gl/aZZmDgbrsWpuMgsu8",
    phoone:"",
    email:"",
  },
 
]