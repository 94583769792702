import React,{useState} from 'react'
import GwetQuoteModal from './GetQuoteModal';

function Banner() {
  const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  return (
    <div className="flex flex-row justify-center items-center gap-4 md:gap-12 py-6 font-bold bg-[#61BC6D]">
           <p className='sm:text-4xl md:text-4xl lg:text-4xl text-[#fff] '>Ready to get started?</p>
           <div className="">
                  <button onClick={openModal}
                         className='px-3 sm:px-6 py-2 sm:py-4 rounded-xl text-xs sm:text-md  bg-[#ffff] text-[#000] '>
                          Get A Free Quote
                  </button>
                  <GwetQuoteModal isOpen={isModalOpen} onClose={closeModal} />
           </div>
    </div>
  )
}

export default Banner