import React, { useState, useEffect , useRef} from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const fileNamesArray = [
  "Hospital Discharge Summary",
  "Pharmacy Bills",
  "Investigation Reports",
  "Hospital Main Bill",
  "Bank Details",
  "Paid Receipts",
  "Claim Form",
];

function UploadDoc() {
    const navigate= useNavigate()
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const claimantName = query.get('claimant_name');
    const companyName = query.get('c_n');
    const email = query.get('e');
    const empCode = query.get('e_c');
    const uhid = query.get('uhid')

    const [claimsList, setClaimsList] = useState([]);
    const [selectedClaim, setSelectedClaim] = useState("Select");
    const hasMounted = useRef(false);

    // Files Start
    // Files Start - Correct state structure
    const [selectedFiles, setSelectedFiles] = useState({});
    const [successMessage, setSuccessMessage] = useState('');


    const handleFileChange = (event, fileType) => {
        const files = Array.from(event.target.files); // Convert FileList to an array
        setSelectedFiles((prevState) => ({
        ...prevState,
        [fileType]: files,
        }));
        console.log("Selected Files:", selectedFiles);
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the form data
    const formData = new FormData();
    formData.append("selectClaim", selectedClaim);
    formData.append("claimantName", claimantName);
    formData.append("companyName", companyName);
    formData.append("email", email);
    formData.append("empCode", empCode);
    formData.append("uhid", uhid);

    // Loop through selectedFiles object
    Object.entries(selectedFiles).forEach(([fileType, files]) => {
      files.forEach((file) => {
        formData.append('files', file); // Append each file under the specific fileType key
      });
    });

    // Send the form data to the API
    try {
      const response = await fetch("https://corphelp.clearcover.in/uploaddocuments-landing-api/", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("File uploaded successfully:", data);
        setSuccessMessage('Files uploaded successfully!');

        // Clear selected files after 3 seconds
        setTimeout(() => {
            setSelectedFiles({}); // Clear the selected files state
            setSuccessMessage('');
            navigate('/')
          }, 5000);
          
      } else {
        console.log("Error uploading files");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
   
    const fetchClaims = async () => {
      try {
        const response = await fetch("https://corphelp.clearcover.in/uploaddocuments-landing", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ companyName, empCode }), // Assuming you have these values
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log("Response data:", data);
          setClaimsList(data.obj.claimsList); // Update state with claims list
        } else {
          console.log("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (!hasMounted.current) {
        fetchClaims();
        hasMounted.current = true; // Mark as mounted
      }
    
  }, [companyName, empCode]); // Add dependencies here if necessary
  

  const handleClaimChange = (e) => {
    setSelectedClaim(e.target.value);
  };

  return (
    <div className="bg-gray-50 py-14">
      <div className="max-w-[95%] md:max-w-[80%] mx-auto bg-white p-8 shadow-xl rounded-lg">
        {/* <div>
            <h1>Upload Document</h1>
            <p>Claimant Name: {claimantName}</p>
            <p>Company Name: {companyName}</p>
            <p>Email: {email}</p>
            <p>Employee Code: {empCode}</p>
            <p>UHID: {uhid}</p>
        </div> */}
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-bold">Upload Documents</h1>
          <hr className="h-[2px] w-[100%] mt-1 bg-green-300" />
        </div>

        <div className="flex flex-col lg:flex-row lg:justify-evenly justify-center items-center lg:items-start mt-10">
                {/* Select Claims */}
                <div className="claim-form">
                    <div className="row"
                        style={{ justifyContent: "space-around", alignItems: "baseline" }}>
                    <div>
                        <div>
                        <select
                            className="rounded-md custom-select outline-none border-2 border-gray-400 px-4 py-1"
                            id="selectClaim"
                            name="claimNumber"
                            value={selectedClaim}
                            onChange={handleClaimChange}
                            style={{ width: "15rem" }}
                        >
                            <option value="Select">Select claim</option>
                            {claimsList.map((claim, index) => (
                            <option key={index} value={claim}>
                                {claim}
                            </option>
                            ))}
                        </select>
                        <span className="error"></span>
                        </div>
                    </div>
                    </div>
                </div>

                {/* Upload files */}
                <div className="flex flex-col gap-10 mt-10 lg:mt-0">
                    {fileNamesArray.map((fileType, index) => (
                    <div key={index}>
                        <div className="flex flex-row items-center gap-10">
                        <div>
                            <select
                            name="documentType"
                            id={`billname${index + 1}`}
                            className="custom-select w-[160px] sm:w-[250px] border border-gray-300 p-2 rounded-md"
                            >
                            <option value={fileType} className="font-bold">
                                {fileType} (Mandatory)
                            </option>
                            </select>
                            <span className="text-red-500 text-sm error"></span>
                        </div>
                        <div>
                            <label className="file bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md cursor-pointer">
                            Upload
                            <input
                                className="uploadbtn hidden outline-none"
                                type="file"
                                name="file"
                                multiple
                                onChange={(e) => handleFileChange(e, fileType)}
                            />
                            </label>
                        </div>
                        </div>

                        {/* Display selected files */}
                        {/* <output id={`list${index + 1}`} className="mt-4">
                        {selectedFiles[fileType] &&
                            selectedFiles[fileType].map((file, i) => (
                            <div key={i} className="text-green-700">
                                {file.name}
                            </div>
                            ))}
                        </output> */}
                        <output id={`list${index + 1}`} className="mt-4">
                            {selectedFiles[fileType] &&
                                selectedFiles[fileType].map((file, i) => {
                                // Extract the file name without the extension
                                const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, ""); // Using regex to remove the extension
                                // Alternatively, you can use the following line
                                // const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.'); // Split and join without the last part

                                return (
                                    <div key={i} className="text-green-700">
                                    {fileNameWithoutExtension}
                                    </div>
                                );
                                })}
                        </output>

                    </div>
                    ))}

                    <div className="flex flex-row gap-10">
                    <div>
                        <select
                        name="documentType"
                        id="billname8"
                        className="custom-select w-[160px] sm:w-[250px] border border-gray-300 p-2 rounded-md"
                        >
                        <option value="Select">Select document type</option>
                        <option value="Self Declaration Form">Self Declaration Form</option>
                        <option value="ID Proof / Address Proof">ID Proof / Address Proof</option>
                        <option value="Doctor's Prescription">Doctor's Prescription</option>
                        <option value="Query Reply">Query Reply</option>
                        <option value="Sticker / Invoice - for Implants">
                            Sticker / Invoice - for Implants
                        </option>
                        <option value="Others">Others</option>
                        </select>
                        <span className="text-red-500 text-sm error"></span>
                    </div>

                    <label className="file bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md cursor-pointer">
                        Upload
                        <input
                        className="uploadbtn hidden"
                        type="file"
                        name="file"
                        id="file-input8"
                        multiple
                        onChange={(e) => handleFileChange(e, "fileInput8")}
                        />
                    </label>
                    </div>

                    {/* Display selected files for file-input8 */}
                    {/* <output id="list8" className="mt-[-30px] mb-8">
                    {selectedFiles.fileInput8 &&
                        selectedFiles.fileInput8.map((file, index) => (
                        <div key={index} className="text-green-700">
                            {file.name}
                        </div>
                        ))}
                    </output> */}

                            <output id="list8" className="mt-[-30px] mb-8">
                            {selectedFiles.fileInput8 &&
                                selectedFiles.fileInput8.map((file, index) => {
                                // Extract the file name without the extension
                                const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, ""); // Remove the extension

                                return (
                                    <div key={index} className="text-green-700">
                                    {fileNameWithoutExtension} {/* Display the file name without the extension */}
                                    </div>
                                );
                                })}
                            </output>
                </div>
        </div>
        <div className="flex flex-row justify-center">
            <button onClick={handleSubmit} 
             className=" w-[100%] md:w-[80%] rounded-md text-[#fff] font-bold text-lg px-4 py-2 bg-green-600 shadow-md">
                Next
            </button>
        </div>
        {successMessage && (
            <div className="mt-4 text-green-600 text-center">
                {successMessage}
            </div>
        )}
      </div>
      
    </div>
  );
}

export default UploadDoc;
