import React,{useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { HiMiniUserGroup } from "react-icons/hi2";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { insurance_cards } from '../../assets/assets';
import GwetQuoteModal from '../../component/GetQuoteModal';
import { useNavigate } from 'react-router-dom';
import ScrollUpText from '../../component/ScrollUpText';

function Scrollbar() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const NextArrow = ({ onClick }) => (
    <div
      className="absolute bottom-[-50px] right-[68%] sm:right-[55%] md:right-[55%] lg:right-[56%] transform translate-x-[60px] md:translate-x-[70px] lg:translate-x-[90px] bg-white p-2 rounded-r-xl cursor-pointer z-10 hover:bg-green-200"
      onClick={onClick}
    >
      <FaArrowRight />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="absolute  left-[55%] sm:left-[55%]  md:left-[56.6%]  bottom-[-50px] xl:left-[53.7%] transform -translate-x-[60px] md:-translate-x-[70px] lg:-translate-x-[90px] bg-white p-2 rounded-l-xl cursor-pointer z-10 hover:bg-green-200"
      onClick={onClick}
    >
      <FaArrowLeft />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 4 slides at a time by default
    slidesToScroll: 1, // Scroll 3 slides at a time by default
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1224, // Large screens (desktops)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // Medium screens (tablets)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640, // Small screens (large mobile)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    
    <div className="py-14 bg-[#F7F9FC] ">
      
      <div className="max-w-[90%] md:max-w-[80%] mx-auto grid items-center sm:gap-5 justify-center text-center px-4">
        <div className="text-[20px] md:text-3xl font-semibold flex flex-col justify-center items-center ml-3">
          <h1 className='text-center'>ClearCover</h1>
          <h1 className="mt-1 text-center">Smart Insurance Solutions</h1>
        </div>

    <div className="relative  w-full max-w-xs sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:mt-2  p-4">
      <Slider {...settings} className="ml-11">
            {insurance_cards.map((card) => (
      <div key={card.id}   onClick={() => handleCardClick(card.path)}
       className="px-2 py-9  flex items-center justify-center">
        <div className="flex flex-col items-center justify-between h-[200px] cursor-pointer bg-white shadow-lg hover:bg-[#fff] rounded-lg hover:shadow-2xl p-6 w-[210px]">
          {/* <HiMiniUserGroup size={30} /> */}
          <img src={card.image} alt="" width="30%" />
          <h3 className="text-[12px] font-semibold mb-2 ">{card.title}</h3>
             <p onClick={(e) => {
              e.stopPropagation(); // Prevent click event from bubbling up to the parent div
              openModal(); // Call the function to open the modal
            }}
             className="text-xs text-[#40c262] font-semibold">{card.content}</p>
        </div>
      </div>
      
    ))}
  </Slider>
    
</div>

      </div>
      <GwetQuoteModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default Scrollbar;
