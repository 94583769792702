import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function CyberSecurityInsurance() {
  return (
  <div className="py-25 ">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Cyber Security Insurance </h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Cyber Security Insurance?</h1>
        <p>Cybersecurity insurance provides coverage against cyber risks, including data breaches, hacking, and cyber-attacks. This policy helps businesses mitigate financial losses and recover quickly from cyber incidents.   </p>


        <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Financial Protection:</li>
                <p>  Covers costs associated with cyber incidents. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Risk Management:</li>
                <p>  Helps manage and mitigate cyber risks. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Regulatory Compliance:</li>
                <p> Ensures compliance with data protection regulations.  </p>
            </ul>

        </div>

        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Data Breach Coverage:</li>
                <p>Protection against data breaches and leaks. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Cyber Extortion:</li>
                <p> Coverage for ransom payments and related costs. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Business Interruption:</li>
                <p>Compensation for income loss due to cyber incidents. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Legal Fees:</li>
                <p>Covers legal costs associated with data breaches.</p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Crisis Management:</li>
                <p>Support for managing public relations and reputation.</p>
            </ul>
        </div>
    </div>
    <div className="">
               <InsuranceSlider/>
             </div>
</div>
  )
}

export default CyberSecurityInsurance