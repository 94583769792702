import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function MarineInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Marine Insurance   </h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Marine Insurance?  </h1>
        <p>Marine Insurance covers transporting goods by sea, air, or land. This policy protects against damage, loss, and theft during transit, ensuring your goods reach their destination safely.   </p>


        <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Comprehensive Coverage:</li>
                <p> Protects goods in sea, air, or land transit. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Risk Management:</li>
                <p> Minimizes financial losses due to transit risks.  </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Customizable Policies: </li>
                <p> Tailored to specific transportation needs. </p>
            </ul>

        </div>

        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Cargo Insurance:</li>
                <p>Coverage for goods transported internationally or domestically. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Hull Insurance:</li>
                <p>Protection for ships and vessels against physical damage. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Freight Insurance:</li>
                <p>Coverage for loss of freight revenue. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Liability Coverage:</li>
                <p>Protection against third-party liabilities.</p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>War Risk Coverage:</li>
                <p>  Protection against war-related risks.</p>
            </ul>
        </div>
    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default MarineInsurance