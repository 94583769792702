import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insurance_cards } from '../assets/assets';
import GwetQuoteModal from '../component/GetQuoteModal';

function Insurance() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();
  
    const handleCardHover = (path) => {
        navigate(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };
  
    return (
        <>
            <div className="py-20 flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Insurance Service</h1>
            </div>

            <div className="py-20 lg:py-32  flex flex-col justify-center items-center">
                <div className="max-w-[80%] mx-auto grid m:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 items-center">
                    {insurance_cards.map((card) => (
                        <div
                            key={card.id}
                            className="flex flex-col items-center justify-between h-[230px] cursor-pointer hover:bg-[#fff] rounded-lg shadow-md hover:shadow-xl p-6 w-[210px]"
                            onClick={() => handleCardHover(card.path)}
                        >
                            <img src={card.image} alt="" width="30%" />
                            <h3 className="text-[12px] text-center font-semibold mb-2">{card.title}</h3>
                            <p
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent click event from bubbling up to the parent div
                                    openModal(); // Call the function to open the modal
                                }}
                                className="text-xs text-[#68D585] font-semibold"
                            >
                                {card.content}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

          <div className="">
            <GwetQuoteModal isOpen={isModalOpen} onClose={closeModal} />

          </div>
        </>
    );
}

export default Insurance;
