import React,{useState} from 'react'
import { MdSlowMotionVideo } from "react-icons/md";
import {assets} from '../../assets/assets';
import GwetQuoteModal from '../../component/GetQuoteModal';
import Scrollbar from './Scrollbar';
import { Link } from 'react-router-dom';

function Herosec() {
    const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };       
  return (<>
   <div className="py-14 md:py-20 bg-[#F7F9FC]">
     <div className="max-w-[90%] md:max-w-[80%] mx-auto grid lg:grid-cols-2 items-center gap-10 px-4 md:mt-10">

        <div className="flex flex-col gap-5">
            <div className="flex-col text-[22px] sm:text-3xl font-bold ">
                <h1>Your Trusted Partner for</h1>     
                <h1 className='text-[#61BC6D]'>Comprehensive Insurance Solutions</h1>
            </div>
            <div className="flex-col gap-0.5 tracking-wide text-[11px] md:text-md text-[#161C2D] mt-[-10px]">
                {/* <p>Your Trusted Partner for Insurance Services. With our expertise </p>
                <p> in insurance, you can secure the best group insurance and other  </p>
                <p>policies tailored to your needs. Trusted by over 100+ clients</p>
                <p>across India.</p> */}
                <p>Trusted Partner for Insurance Services. Leveraging our expertise, we offer tailored group insurance and a range of policies to meet your needs. Trusted by over 100 clients across India.</p>
            </div>

            <div>
                <button onClick={openModal}
                        className=' w-[50%] sm:w-[22%] py-2 rounded-lg text-[#ffffff]  bg-[#61BC6D]'>
                            Get a Quote
                </button>
                <GwetQuoteModal isOpen={isModalOpen} onClose={closeModal} />
            </div>
            
            <a href="https://www.youtube.com/channel/UCBh3Y-xnBGDnQo5fKz3JoQQ" target='_blank' rel='noopener noreferrer'
               className="flex flex-row gap-2 items-center">
               <MdSlowMotionVideo className='text-[#e26360]'/>   {/* #FFA8A7 */}
                <p className='text-[#161C2D] text-[11px] sm:text-sm font-medium'>Watch how we can help you protect your future.</p>
            </a>
        </div>

        <div className="flex flex-col justify-center items-center">
            {/* <img src={assets.hero_img} alt="" /> */}
            <img src={assets.home_gif} alt="" className='' />
        </div>

     </div>

     <div className="max-w-[90%] md:max-w-[80%] mx-auto px-4 mr-8 md:mr-auto md:mt-20">
      
          <Scrollbar/>
     </div>

   </div>

   {/* <Link
        to="/downloadClaim"
        className="inline-block px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
      >
        Download Claim Form
      </Link> */}
   {/* <button className="px-4 py-2 text-white font-semibold rounded-md bg-gradient-to-r from-green-500 to-blue-500 bg-[length:200%_100%] bg-left-bottom hover:bg-right-bottom transition-bg-position duration-500">
   Hover Me
 </button> */}
   
 
 </> )
}


export default Herosec