// import React, { useState } from 'react';
// import { RxCross2 } from "react-icons/rx";

// const JobForm = ({ isOpen, onClose }) => {
//   const [message, setMessage] = useState(''); 
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     resume: null,
//   });

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === 'resume') {
//       setFormData(prev => ({
//         ...prev,
//         [name]: files[0], // Handle file uploads
//       }));
//     } else {
//       setFormData(prev => ({
//         ...prev,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const form = new FormData();
//     form.append('name', formData.name);
//     form.append('email', formData.email);
//     form.append('phone', formData.phone);
//     form.append('resume', formData.resume);

//     console.log("formData")
//     console.log(formData)

//     try {
//       // const response = await fetch("https://corphelp.clearcover.in/api/get-job-form", {
//         const response = await fetch("https://corphelp.clearcover.in/api/get-job-form", {
//         method: "POST",
//         body: form, // Send FormData object
//       });
//       console.log(response)

//       if (response.ok) {
//         setMessage('Your form has been submitted successfully!');
//         setTimeout(() => {
//           setMessage(''); // Clear message after 2 seconds
//           onClose(); // Close the modal
//         }, 2000);
//       } else {
//         console.log("Comming in errror.......")
//         setMessage('Failed to submit the form.');
//       }
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       setMessage('An error occurred while submitting the form.');
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center z-50">
//       <div className="bg-black bg-opacity-25 absolute inset-0" onClick={onClose}></div>
//       <div className="bg-white rounded-lg p-8 shadow-lg z-10">
//         <div className="flex flex-row justify-between mb-4">
//           <h2 className="text-xl text-[#61BC6D] font-extrabold">Apply for Job</h2>
//           <RxCross2 onClick={onClose} size={30} className="text-[#61BC6D] font-extrabold hover:text-red-800" />
//         </div>

//         <div className="mb-4">Resume/CV <span className="text-red-300">*</span></div>

//         <form onSubmit={handleSubmit} className="flex flex-col gap-4">
//           <div className="flex flex-row items-center gap-2">
//           <p><input
//               type="file"
//               id="fileInput"
//               name="resume"
//               className="absolute opacity-0 h-0 w-0"
//               accept=".doc,.docx,.pdf"
//               onChange={handleChange}
//             />
//             <label htmlFor="fileInput"
//                    className="py-2 px-4 border border-green-500 rounded-md cursor-pointer">
//                    Select Resume
//             </label></p>
//             <p className="text-sm">Upload in DOC, DOCX, or PDF (file size &lt; 1MB)</p>
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="name" className="font-medium">Name</label>
//             <input
//               type="text"
//               name="name"
//               placeholder='Enter Name'
//               value={formData.name}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="email" className="font-medium">Email</label>
//             <input
//               type="email"
//               name="email"
//               placeholder='Enter Email'
//               value={formData.email}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//           </div>

//           <div className="grid grid-cols-1 gap-1">
//             <label htmlFor="phone" className="font-medium">Phone</label>
//             <input
//               type="number"
//               name="phone"
//               placeholder='Enter Phone'
//               value={formData.phone}
//               onChange={handleChange}
//               className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
//               required
//             />
//           </div>

//           <div className="flex justify-center items-center bg-[#61BC6D] rounded-md mt-4">
//             <button type="submit" className="p-3 rounded-md text-white font-bold">
//               Submit Application
//             </button>
//           </div>
//         </form>

//         {message && <div className="text-center text-green-600 font-bold mt-3">{message}</div>}
//       </div>
//     </div>
//   );
// };

// export default JobForm;

import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';

const JobForm = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    resume: null,
  });

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      const file = files[0];
      if (file && file.size > MAX_FILE_SIZE) {
        setErrorMessage('File size exceeds the 10 MB limit. Please upload a smaller file.');
        setTimeout(()=>{
          setErrorMessage('')
        },2000)
        setFormData(prev => ({ ...prev, [name]: null })); // Reset resume if size exceeds
      } else {
        setErrorMessage(''); // Clear error if file size is valid
        setFormData(prev => ({
          ...prev,
          [name]: file, // Handle file uploads
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Ensure a resume is uploaded before submission
    if (!formData.resume) {
      setErrorMessage('Please upload a resume.');
      return;
    }

    const form = new FormData();
    form.append('name', formData.name);
    form.append('email', formData.email);
    form.append('phone', formData.phone);
    form.append('resume', formData.resume);

    try {
      const response = await fetch("https://corphelp.clearcover.in/api/get-job-form", {
        method: "POST",
        body: form, // Send FormData object
      });

      if (response.ok) {
        setMessage('Your form has been submitted successfully!');
        setTimeout(() => {
          setMessage(''); // Clear message after 2 seconds
          onClose(); // Close the modal
          navigate('/career')
        }, 4000);
      } else {
        setMessage('Failed to submit the form.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('An error occurred while submitting the form.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-25 absolute inset-0" onClick={onClose}></div>
      <div className="bg-white rounded-lg p-8 shadow-lg z-10">
        <div className="flex flex-row justify-between mb-4">
          <h2 className="text-xl text-[#61BC6D] font-extrabold">Apply for Job</h2>
          <RxCross2 onClick={onClose} size={30} className="text-[#61BC6D] font-extrabold hover:text-red-800" />
        </div>

        <div className="mb-4">Resume/CV <span className="text-red-300">*</span></div>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <p>
              <input
                type="file"
                id="fileInput"
                name="resume"
                className="absolute opacity-0 h-0 w-0"
                accept=".doc,.docx,.pdf"
                onChange={handleChange}
              />
              <label htmlFor="fileInput" className="py-2 px-4 border border-green-500 rounded-md cursor-pointer">
                Select Resume
              </label>
            </p>
            <p className="text-sm">Upload in DOC, DOCX, or PDF (file size 10 MB)</p>
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="name" className="font-medium">Name</label>
            <input
              type="text"
              name="name"
              placeholder='Enter Name'
              value={formData.name}
              onChange={handleChange}
              className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
              required
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="email" className="font-medium">Email</label>
            <input
              type="email"
              name="email"
              placeholder='Enter Email'
              value={formData.email}
              onChange={handleChange}
              className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
              required
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="phone" className="font-medium">Phone</label>
            <input
              type="number"
              name="phone"
              placeholder='Enter Phone'
              value={formData.phone}
              onChange={handleChange}
              className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
              required
            />
          </div>

          <div className="flex justify-center items-center bg-[#61BC6D] rounded-md mt-4">
            <button type="submit" className="p-3 rounded-md text-white font-bold">
              Submit Application
            </button>
          </div>

          <p className='h-1'>
          {errorMessage && <div className="text-center  text-[14px] text-red-500 font-bold mt-0.5">{errorMessage}</div>}
          </p>
        </form>

        <p className='h-1'>
         {message && <div className="text-center text-green-600 font-bold mt-0.5">{message}</div>}
        </p>
      </div>
    </div>
  );
};

export default JobForm;

