import React, {useState} from 'react'
import {assets} from '../../assets/assets'
import HomeCrm from './HomeCrm' 
import {Link} from 'react-router-dom'
import Banner from '../../component/Banner'
import GwetQuoteModal from '../../component/GetQuoteModal'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { aboutusslider_cards} from '../../assets/assets'
import { blog_cards} from '../../assets/assets' 
import GetAQuote from '../../component/GetAQuote'
import BrandSlider from '../../component/BrandSlider'
import { FaArrowRight } from "react-icons/fa";


function HomeSection() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        centerMode: true,
        centerPadding: '0px',
        rtl: false, 
        
        customPaging: i => (
          <div
            className="w-2 h-2 bg-green-300 rounded-full mx-2 cursor-pointer hover:bg-[#61BC6D]"
          ></div>
        ),
        dotsClass: "slick-dots flex justify-center ",
        appendDots: dots => (
          <div className="slick-dots flex justify-center  ">
            {dots.slice(0, 4)} {/* Only show the first three dots */}
          </div>
        ),
        responsive: [
          {
              breakpoint :640,
              settings:{
                  slidesToShow:1.5,
                  slidesToScroll:1,
              }
          }
        ]
      };

  return (<>
     <div className="relative py-14">
           <div className=" absolute right-0  ">
              <img src={assets.dot_1} alt="" className='h-[100px] sm:h-auto' />
           </div>
            
         <div className="  max-w-[90%] md:max-w-[80%] mx-auto grid lg:grid-cols-2 gap-16 lg:gap-32 items-center justify-center">
             <div className="flex flex-col items-center justify-center">
                 <img src={assets.home_experience} alt="" />
             </div>
             <div className="flex flex-col gap-5 text-center lg:text-start">
                        <div className="flex flex-col text-[25px] md:text-4xl lg:text-3xl font-bold ">
                            <h1>Experience Seamless </h1>
                            <h1>Insurance with ClearCover</h1>
                        </div>
                        <div className="flex-col gap-0.5 tracking-widest lg:tracking-wide text-[10px] md:text-[14px] text-[#161C2D]">
                            <p>Handling insurance claims shouldn't bring additional stress to an</p>
                            <p>already difficult situation. At ClearCover, </p>
                            <p>we’ve simplified our claims process to ensure it’s quick, </p>
                            <p>fair, and stress-free.</p>
                               
                        </div>
             </div>

         </div>
         
     </div>

{/* ####################### Why chosse Us ################################# */}

    <div className= " py-14 bg-[#F7F9FC]">
        <div className="max-w-[90%] md:max-w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 items-center px-4 justify-center ">

            <div className="flex felx-row justify-center">
                <div className="flex flex-col justify-center gap-5">
                    <div className="flex flex-col text-[26px] sm:text-3xl  font-bold ">
                        <h1>Why Choose Us? </h1>
                    </div>
                    <div className="flex flex-col gap-4 tracking-wide text-[10px] md:text-[14px] text-[#161C2D]">
                                <div className="text-[10px] sm:text-[13px] font-normal">
                                    <img src={assets.right_tick} alt="" className='inline mr-2 sm:mr-4 w-[15px]'  />
                                    <span className=''>Tailored Coverage</span>
                                </div>

                                <div className="text-[10px] sm:text-[13px] font-normal">
                                    <img src={assets.right_tick} alt="" className='inline mr-2 sm:mr-4 w-[15px]' />
                                    <span className=''>Transparent Policies</span>
                                </div>
                                
                                <div className="text-[10px] sm:text-[13px] font-normal">
                                    <img src={assets.right_tick} alt="" className='inline mr-2 sm:mr-4 w-[15px]' />
                                    <span className=''>Integrated CRM Support</span>
                                </div>
                                
                                <div className="text-[10px] sm:text-[13px] font-normal">
                                    <img src={assets.right_tick} alt="" className='inline mr-2 sm:mr-4 w-[15px]' />
                                    <span className=''>24/7 Customer Support</span>
                                </div>
                    </div>
                </div>
            </div>

            <div className="flex  flex-row justify-center items-center">   
                <img src={assets.home_question} alt="" className='w-[80%] ' />   
                  {/* <img src={assets.home_choose_dots} alt="" className=' h-[100px] md:h-[140px] lg:h-[180px] z-[1] absolute bottom-[435px] right-[-30px]' /> */}
                  
            </div>
        </div>
    </div>
    {/* <HomeCrm/> */}

     {/* ##############   OUR Core Product _--- CRM ############### */}
     <div className="relative z-[-2]">
            <div className="flex flex-row justify-between">
                <div className="">
                    <img src={assets.home_crm_rectangle} alt="" className=' sm:[200px] md:[300px] lg:[400px]  xl:w-[500px] translate-y-[-18%] z-[-2]'   />
                </div>
                <div className=" relative hidden sm:flex flex-col items-end justify-end translate-0 translate-y-[50%] sm:translate-y-[12%] md:translate-y-[29%] lg:translate-y-[-30%] xl:translate-y-[-45%] top-20 ">
                    
                    <img src={assets.dot_1} alt="" className='w-[80px] md:w-auto' />
                </div>
            </div>

            <div className="absolute top-[10%] left-[8%] max-w-[90%] md:max-w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-44 lg:gap-32 items-center justify-enter px-4">
                    <div className="flex felx-col items-center justify-center">
                        <img src={assets.home_crm_mackbook} alt="" />
                    </div>
                    <div className="flex flex-col gap-5 md:gap-10">
                                <div className=" flex flex-col gap-3">
                                        <div className="flex flex-col text-[20px] md:text-4xl lg:text-3xl font-bold ">
                                                {/* <h1>Our Core Product: Dedicated CRM</h1> */}
                                                <h1>Our Core Product: Tailored CRM for Your Business Needs</h1>
                                            </div>
                                            <div className="flex-col gap-0.5  text-[10px] md:text-[13px] text-[#161C2D]">
                                                <p>At ClearCover, we simplify the insurance experience with our dedicated CRM. This all-in-one system streamlines policy management, claims processing, and customer support, giving you full control and visibility over your insurance portfolio. With our CRM, you can :</p>
                                            </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="">
                                        <h1 className='text-md font-semibold'>Efficient Policy Management: </h1>
                                        <p className='text-[13px]'>Access all policy details and documents in one convenient location.</p>
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="">
                                        <h1 className='text-md font-semibold'>Seamless Claims Processing:</h1>
                                        <p className='text-[13px]'>Submit and track claims with ease.</p>
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="">
                                        <h1 className='text-md font-semibold'>Enhanced Customer Support: </h1>
                                        <p className='text-[13px]'>Receive real-time assistance from our dedicated support team.</p>
                                    </div>
                                </div>
                    </div>

            </div>

     </div>

     

    {/* ################   HR And Employee Portal ######################## */}
    {/* <div className="relative mt-[450px]  sm:mt-[34%]  md:mt-[39%] lg:mt-[-60px]">
            <img src={assets.dot_1} alt="" className='w-[80px] sm:w-auto h-[100px] sm:h-auto'/>
            <div className="flex flex-row justify-center items-center absolute top-[27%] md:top-[50%] left-[35%]">
                 <h1 className=' text-[25px] md:text-4xl font-bold '>HR and Employee Portals</h1>
            </div>
    </div> */}

    <div className="flex flex-row items-center gap-[10%]  lg:gap-[25%]  mt-[400px] sm:mt-[250px] md:mt-[327px] lg:mt-0">
            <img src={assets.dot_1} alt="" className='w-[80px] sm:w-auto h-[100px] sm:h-auto '/>
            <div className="">
                 <h1 className=' text-[25px] md:text-4xl font-bold '>HR and Employee Portals</h1>
            </div>
    </div>


     {/* ###############     HR PORTAL ###################### */}
    <div className="max-w-[90%] md:max-w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10 items-center px-4 justify-center mt-10 md:mt-0">

            <div className="flex felx-row justify-center">
                <div className="flex flex-col justify-center gap-4 md:gap-8">
                    <div className="flex flex-col text-[26px] sm:text-3xl  font-bold ">
                        <h1>HR Portal </h1>
                    </div>
                    <div className="flex-col gap-0.5 tracking-widest lg:tracking-wide text-[10px] md:text-[15px] text-[#161C2D]">
                            {/* <p>A dedicated portal to access all your company details along with employee </p>
                            <p>details, claims, E-cards, policy terms, and documents—all in one place. </p>
                            <p>wSimplify the management of your company's insurance needs with our  </p>
                            <p>comprehensive HR portal designed for efficiency and ease of use. </p> */}
                            <p>A dedicated portal to access all your company details along with employee details, claims, E-cards, policy terms, and documents—all in one place.we simplify the management of your company's insurance needs with our comprehensive HR portal designed for efficiency and ease of use. </p>
                    </div>
                </div>
            </div>

            <div className="relative flex  flex-row  justify-center items-center ">   
                 <img src={assets.home_employee_macbook} alt="" />
            </div>
    </div>

    {/* ##########################   Employee Portal ########################## */}
    <div className="max-w-[90%] md:max-w-[80%] mx-auto hidden lg:grid grid-cols-1 lg:grid-cols-2 gap-10 items-center px-4 justify-center mt-10 ">

            <div className="relative flex flex-row justify-center items-center ">   
                <img src={assets.home_employee} alt="" className=' w-[60%]  z-[-2]'/>
            </div>

            <div className="flex felx-row justify-center">
                <div className="flex flex-col justify-center gap-4 md:gap-8">
                    <div className="flex flex-col text-[26px] sm:text-3xl  font-bold ">
                        <h1>Employee Portal</h1>
                    </div>
                    <div className="flex-col gap-0.5 tracking-widest lg:tracking-wide text-[10px] md:text-[15px] text-[#161C2D]">
                            {/* <p>A dedicated Employee portal provides your employees with seamless  </p>
                            <p>access to all their insurance details, including claims, E-cards, policy terms,  </p>
                            <p>and documents. Empower your employees with easy access to their  </p>
                            <p>insurance benefits and make managing their coverage straightforward and  </p>
                            <p>hassle-free.</p> */}
                            <p>A dedicated Employee portal provides your employees with seamless access to all their insurance details, including claims, E-cards, policy terms,and documents. Empower your employees with easy access to their insurance benefits and make managing their coverage straightforward and hassle-free. </p>
                    </div>
                </div>
            </div>
    </div>

     <div className="max-w-[90%] md:max-w-[80%] mx-auto lg:hidden grid grid-cols-1 lg:grid-cols-2 gap-10 items-center px-4 justify-center mt-10 ">
            <div className="flex felx-row justify-center">
                <div className="flex flex-col justify-center gap-4 md:gap-8">
                    <div className="flex flex-col text-[26px] sm:text-3xl  font-bold ">
                        <h1>Employee Portal</h1>
                    </div>
                    <div className="flex-col gap-0.5 tracking-widest lg:tracking-wide text-[10px] md:text-[15px] text-[#161C2D]">
                            {/* <p>A dedicated Employee portal provides your employees with seamless  </p>
                            <p>access to all their insurance details, including claims, E-cards, policy terms,  </p>
                            <p>and documents. Empower your employees with easy access to their  </p>
                            <p>insurance benefits and make managing their coverage straightforward and  </p>
                            <p>hassle-free.</p> */}
                            <p>A dedicated Employee portal provides your employees with seamless access to all their insurance details, including claims, E-cards, policy terms,and documents. Empower your employees with easy access to their insurance benefits and make managing their coverage straightforward and hassle-free. </p>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-row justify-center items-center ">   
                <img src={assets.home_employee} alt="" className='z-[-2]'/>
            </div>
    </div>

    <div className=" mt-[-6%] lg:mt-[-2%]">
        <Banner/>       
    </div>

    {/* ##################### Our Valued Clients ################################ */}

   


        <div className="py-14 bg-[#F4F7FA] flex flex-col ">
            <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center px-4 md:mt-8 ">
                    <div className="flex flex-col  items-center gap-4">
                            <h1 className='sm:text-4xl lg:text-[25px] xl:text-[32px] font-bold '>Our Valued Clients</h1>
                            <div className="flex-col gap-0.5 tracking-wide text-[10px] md:text-[14px] lg:text-[14px] xl:text-[16px] text-[#161C2D]">
                                <p>Proudly partnering with over 100+ clients nationwide </p>
                            </div>
                        </div>

                        <div className="flex flex-row justify-center items-center">
                            <BrandSlider/>
                        </div>
            </div>
        </div>

    {/*#####################  Simplify get it now ############################### */}

        <div className="py-14 max-w-[90%] md:max-w-[80%] mx-auto grid lg:grid-cols-2 items-center gap-7 lg:gap-20 px-4">
                    <div className="flex flex-col items-center gap-5">
                        <div className="flex flex-col gap-2 sm:gap-5">
                                <div className="flex-col sm:text-4xl lg:text-[25px] xl:text-[32px] font-bold ">
                                    <h1>Simplify Your Insurance Process</h1>
                                </div>
                                <div className="flex-col gap-0.5 tracking-wide text-[10px] md:text-[14px] lg:text-[14px] xl:text-[16px] text-[#161C2D]">
                                    <p>With our user-friendly interface and robust features, </p>
                                    <p>managing insurance has never been easier. Get started </p>
                                    <p>today and enjoy peace of mind.</p>
                                </div>
                        </div>
                    </div>

                    <div className="flex flex-row gap-4 items-center justify-center">
                        <Link to="/about" className="flex flex-row gap-3 items-center justify-center rounded-lg bg-[#fffff9] shadow-lg px-4 py-3">
                            <button className='text-[#61BC6D] text-xs sm:text-md font-semibold'>Learn more</button>
                            <img src={assets.right_arrow} alt="" className='mt-1' />
                        </Link>

                        <div className="">
                           <div onClick={openModal} className="flex flex-row gap-3 items-center justify-center rounded-lg bg-[#61BC6D] shadow-lg px-4 py-3">
                               <button className='text-[#ffffff] text-xs sm:text-md font-semibold'>Get it now</button>
                               <img src={assets.tail_right_white} alt="" className='mt-1' />
                            </div>
                            <GwetQuoteModal isOpen={isModalOpen} onClose={closeModal} />


                        </div>
                        
                    </div>
        </div>

    {/* ####################  About Slider ###################################### */}

    <div className=" pb-10 pt-16 grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-0 items-center  bg-[#F4F7FA] ">
                <div className="lg:col-span-1   flex flex-col justify-center items-center " >
                      <div className="w-[100%]  bg-[#F4F7FA] flex flex-col items-center justify-center text-3xl lg:text-4xl font-bold ">
                          <p>What people </p>
                         
                          <p>say <span className='text-[#61BC6D]'>About Us<span className='text-[28px] mt-[-2px] ml-0.5 '>:</span></span></p>
                          
                      </div>
                </div>

                <div className="lg:col-span-2 ">
                    <div className=" overflow-hidden pb-4">
                        <Slider {...settings} className='pb-8'>
                        {aboutusslider_cards.map((card) => (
                            <div key={card.id} className="p-2 ml-[50%] ">
                            <div className="bg-white p-7 rounded-lg shadow-lg">
                                <div className="grid grid-flow-row gap-4 sm:gap-5 ">
                                <img src={card.image} alt="creator" className='w-[50px]' />
                                <p className="text-[#161C2D] text-[7px] sm:text-xs lg:text-md tracking-wide leading:4 sm:leading-5 md:leading-7 lg:leading-7">
                                    {card.review}
                                </p>
                                <div className="flex flex-col">
                                    <p className="text-xs lg:text-lg font-bold">{card.name}</p>
                                    <span className="text-[7px] lg:text-xs font-thin">
                                    {card.company}
                                    </span>
                                </div>
                                </div>
                            </div>
                            </div>
                        ))}
                        </Slider>
                    </div>
                </div>
        </div> 

    {/* ############################   Blog ######################################### */}
        
    {/* <div className=" py-20 max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-10 justify-center items-center">
                    <h1 className='text-[22px] sm:text-5xl font-bold '>Blogs that helps</h1>
                    
                    <div className="flex flex-col gap-0.5 justify-center items-center  text-[10px] sm:text-lg lg:text-[14px] xl:text-[20px] tracking-wider text-[#161C2D]">
                        <p>With lots of unique blocks, you can easily build a page </p>
                        <p>without coding. Build your next landing page.</p>
                    </div>

                    <div className="flex flex-row items-center mt-10 ">
                     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 items-center">
                        {blog_cards.map((item, index) => (
                            <div key={index} className="w-[200px] h-[250px] shadow-sm">
                            <img  src={item.image} alt={item.title} className='shadow-xl shadow-green-100 ' />
                            <div className="flex flex-col gap-2 p-3">
                                <span className='text-[10px] font-thin'>{item.title}</span>
                                <p className='text-[12px] font-semibold'>{item.content}</p>
                            </div>
                            </div>
                        ))}
                      </div>
                    </div>

    </div> */}

<div className="py-20 max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-6 justify-center items-center">
    <h1 className='text-[22px] sm:text-5xl font-bold text-center'>Discover Our Latest Insights</h1>
    
    <div className="flex flex-col gap-1 justify-center items-center text-center text-[12px] sm:text-lg lg:text-[14px] xl:text-[20px] tracking-wide text-[#161C2D]">
        <p>Explore our collection of articles designed to inform and inspire.</p>
        <p>Stay updated with trends, tips, and insights from our experts.</p>
    </div>

    <div className="flex flex-row items-center mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12 items-center">
                {blog_cards.map((item) => (
                 <Link to={item.path}>
                    <div key={item.id} className="relative w-full h-[320px] rounded-lg shadow-lg overflow-hidden transition-transform duration-300 ease-in-out hover:scale-105">
                        <img src={item.image} alt={item.title} className='h-full w-full object-fill'/>
                        <div className="absolute bottom-0 p-4 bg-gradient-to-t from-black via-transparent to-transparent text-white">
                            <span className='block text-sm font-semibold'>{item.title}</span>
                            <p className='text-xs mt-1'>{item.content}</p>
                        </div>
                        <a href={`/blogs/${item.id}`} className="absolute inset-0" aria-label={`Read more about ${item.title}`}></a>
                    </div>
                 </Link>
                ))}
                <div className=" flex flex-col justify-center items-center  transition-transform duration-300 ease-in-out hover:scale-105 ">
                    <p className='text-[14px] font-bold'>Looking for more insightful articles?</p>
                    <div className="flex flex-row items-center gap-2 text-[#61BC6D] ">
                        <a href='/firstBLog' className='text-lg font-bold'>Explore All Blogs</a>
                        {/* <img src={assets.arrow_right_blue} alt="" className='bg-[#61BC6D]'/> */}
                        <FaArrowRight size={15} className='text-[#61BC6D]'/>
                    </div>
            </div>
                
                    
            </div>


    </div>

</div>




    {/* ###################  Get A Query    ##################### */}
        <div className="pb-14 md:py-10 lg:py-20 ">
                    <div className="max-w-[90%] md:max-w-[80%] mx-auto grid lg:grid-cols-2 items-center gap-16 lg:gap-32 px-4  ">

                        <div className="flex flex-col items-center gap-5">
                            <div className="flex flex-col gap-5">
                                <div className="flex flex-col md:gap-1 text-[22px] sm:text-4xl font-bold ">
                                    <img src={assets.chat} alt="" className='w-[20%]' />
                                    <h1 className='mt-10'>Have Any Queries?</h1>
                                    <h1 className='text-[#61BC6D]'>Talk to an Expert</h1>
                                </div>
                                <div className="flex-col gap-0.5 tracking-wide text-[10px]  lg:text-[14px] xl:text-[16px] text-[#161C2D]">
                                    <p>Got questions or need assistance? Our team  </p>
                                    <p>of experienced experts is here to help you.</p>
                                </div>
                            </div>
                        </div>
                        <p>
                            <GetAQuote/>
                        </p>
                
                    </div>
                   
                    
                   
        </div>


     </>)
}

export default HomeSection