import React,{useState} from 'react'
import { MdLocationPin } from "react-icons/md";
import { MdWifiCalling3 } from "react-icons/md";
import { MdOutgoingMail } from "react-icons/md";
import { branch_contact } from '../assets/assets';

function Contact() {
    const [message, setMessage] = useState(''); 
    const[contactForm, setContactForm]=useState({
        name:'',
        email:'',
        subject:'',
        message:'',
    })
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });

        // Validation functions
  const nameValidation = (name) => {
    // if (!name.trim()) return "Name is required.";
    if (name.length < 3) return "Name must be at least 3 characters.";
    if (!/^[A-Za-z\s]+$/.test(name)) return 'Only letters and spaces allowed';
    if (name.length > 50) return "Name cannot exceed 50 characters.";
    // if (/\s{2,}/.test(name)) return "Name cannot contain consecutive spaces.";
    // if (/^\s|\s$/.test(name)) return "Name cannot start or end with a space.";
    return "";
  };

  const emailValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    const consecutiveDotsRegex = /\.{2,}/;
    const domainPart = email.split('@')[1];
  
    // if (!email.trim()) return 'Email is required';
    if (!emailRegex.test(email)) return 'Invalid email format (e.g., mail@example.com)';
    if (consecutiveDotsRegex.test(email))
      return 'Email address cannot contain consecutive dots';
    if (email.startsWith('.') || email.endsWith('.'))
      return 'Email address cannot start or end with a dot';
    if (!domainPart || domainPart.split('.').length < 2)
      return 'Email must include a valid domain (e.g., example.com)';
    if (domainPart.split('.')[0].length < 2)
      return 'Domain part of email must be valid (e.g., example.com)';
  
    // Known domain validation
    const knownDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];
    const domain = domainPart.toLowerCase();
    if (!knownDomains.some((d) => domain.endsWith(d))) {
      return 'Email domain is not recognized. Use a common email provider.';
    }
  
    return '';
  };


    const handleChange=(e)=>{
         const{name, value}=e.target;
         setContactForm(( prev )=>({
            ...prev,
            [name]:value,
         }));

         let error = "";
      if (name === "name") error = nameValidation(value);
      if (name === "email") error = emailValidation(value);
  
      setErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    };
    
    const handleSubmit = async (e)=>{
        e.preventDefault();
        console.log(contactForm)
        const nameError = nameValidation(contactForm.name);
      const emailError = emailValidation(contactForm.email);

      if (nameError || emailError ) {
        setErrors({
        name: nameError,
        email: emailError,
        });
        return;
     }
        try {
            const response = await fetch("https://corphelp.clearcover.in/api/get-a-contact",{
                method: "POST",
                headers: {
                    "Content-Type":"application/json"
                },
                body:JSON.stringify(contactForm),
            });
            console.log(response)
            if(response.ok){
                setMessage('Your ticket has been raised!');
                console.log("Ticket Raised...");
    
                setTimeout(() => {
                    setMessage(''); 
                  }, 2000);

                setContactForm({
                    name:'',
                    email:'',
                    // subject:'',
                    message:'',
                 })
            }
        } catch (error) {
            console.error(error)
        }
    }
  return (
        <div className="pb-14">
            <div className="py-20  flex flex-col justify-center items-center text-[22px]   bg-[#f4f7fa]">
                <h1 className='sm:text-4xl font-bold'>Contact <span className='text-[#61BC6D]'>us</span></h1>
                {/* <h1 className='text-[#61BC6D]'>Group Health Insurance</h1> */}
                <p className='px-4 text-center text-[16px] mt-2'>We aim to be ClearCover most trusted platform for personalized health     benefits for employees and their families.</p>
                <p className='text-center text-[16px]'>Connect with us to discover tailored solutions for well-being and peace of mind.</p>
            </div>

            <div className=" max-w-[100%] md:max-w-[80%] mx-auto flex felx-col items-center">
                
                <div className="px-4 grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-0  justify-center  mt-14">        
                     {/* ###### First Column ###### */}
                    <div className="flex flex-col gap-10 ">
                        <div className="">
                            <h1 className='text-xl text-[#61BC6D] font-bold'>Corporate Office</h1>

                            <div className="shadow-md p-4 rounded-lg mt-4">
                                <h2 className='text-lg font-semibold text-[#9ca3af] mb-0.5'>Bangalore</h2>
                                <p className='text-[15px] font-semibold text-[#9ca3af] mb-2'>Branch Manager: Harish T </p>

                                <div className="space-y-2 mt-1">
                                        <div className="transform transition-transform duration-300 ease-in-out hover:translate-x-1">
                                                <a
                                                    href="https://maps.app.goo.gl/mCc6VLZ26AqHxBJ98"
                                                    className="flex items-center text-[#00000073] hover:text-[#3B82F680]"
                                                >
                                                    <MdLocationPin className="inline text-lg mr-2" />
                                                    <span className="text-[14px]">
                                                    1st floor, 2gethr @ ORR TOWER-B, MANTRI COMMERCIO Outer Ring Rd, Bellandur, 560103
                                                    </span>
                                                </a>
                                        </div>

                                        <div className="flex items-center space-x-2 transform transition-transform duration-300 ease-in-out hover:translate-x-1 text-[#00000073]">
                                            <MdWifiCalling3 className='text-lg' />
                                            <a href="tel:+919133309629" className="hover:text-[#3B82F680] text-[14px]">
                                                7026371000
                                            </a>
                                        </div>

                                        <div className="flex items-center space-x-2 transform transition-transform duration-300 ease-in-out hover:translate-x-1 text-[#00000073]">
                                            <MdOutgoingMail className='text-lg' />
                                            <a href="mailto:esupport@Clearcover.in" className="hover:text-[#3B82F680] text-[14px]">
                                                support@ClearCover.in
                                            </a>
                                        </div>
                                </div>
                            </div>

                        </div>


                    <div className="space-y-6">
                        <span className='text-2xl font-bold text-[#61BC6D]'>Branch Offices</span>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="shadow-lg p-6 rounded-lg bg-white transition-transform duration-300 ease-in-out transform hover:scale-105">
                                    <h2 className='text-xl font-semibold text-[#9ca3af] mb-2'>Delhi NCR</h2>
                                    <p className='text-[15px] font-semibold text-[#9ca3af] mb-2'>Branch Manager: Vikash Kumar</p>

                                    <a
                                        href="https://maps.app.goo.gl/E1QjAo78KtfzJSj46"
                                        className='flex items-center space-x-2 hover:text-[#3B82F680] text-[#00000073]'
                                    >
                                        <MdLocationPin size={45}/>
                                        <p className='text-sm'>
                                        Connect HQ Coworking F2, F Block, Sector 8, Noida, Uttar Pradesh 201301
                                        </p>
                                    </a>
                                    <div className="mt-2 text-[#00000073]">
                                        <div className="flex items-center space-x-2">
                                        <MdWifiCalling3  className='text-xl' />
                                        <a href="tel:+918076658190" className="text-sm hover:text-[#3B82F680]">8076658190</a>
                                        </div>
                                        <div className="flex items-center space-x-2 mt-1">
                                        <MdOutgoingMail className='text-xl' />
                                        <a href="mailto:esupport@Clearcover.in" className="text-sm hover:text-[#3B82F680">vikash@mdhinsurance.in </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="shadow-lg p-6 rounded-lg bg-white transition-transform duration-300 ease-in-out transform hover:scale-105">
                                    <h2 className='text-xl font-semibold text-[#9ca3af] mb-0.5'>Hyderabad</h2>
                                    <p className='text-[15px] font-semibold text-[#9ca3af] mb-2'>Branch Manager: Prasad P S </p>

                                    <a
                                        href="https://maps.app.goo.gl/BZrpTjnpQPT4xN4r5"
                                        className='flex items-center space-x-2 hover:text-[#3B82F680] text-[#00000073]'
                                    >
                                        <MdLocationPin size={50}  />
                                        <p className='text-sm'>
                                        Plot No. 73, SMR House 4th Floor, Nagarjuna Hills, Panjagutta, Hyderabad-82, Telangana
                                        </p>
                                    </a>
                                    <div className="mt-2 text-[#00000073]">
                                        <div className="flex items-center space-x-2">
                                        <MdWifiCalling3 className='text-xl' />
                                        <a href="tel:+919391183220" className="text-sm hover:text-[#3B82F680]">9391183220</a>
                                        </div>
                                        <div className="flex items-center space-x-2 mt-1">
                                        <MdOutgoingMail className='text-xl' />
                                        <a href="mailto:esupport@Clearcover.in" className="text-sm hover:text-[#3B82F680]">support@ClearCover.in</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="shadow-lg p-6 rounded-lg  bg-white transition-transform duration-300 ease-in-out transform hover:scale-105">
                                    <h2 className='text-xl font-semibold text-[#9ca3af] mb-0.5'>Bangalore</h2>
                                    <p className='text-[15px] font-semibold text-[#9ca3af] mb-2'>Branch Manager: Suchithra Naidu </p>

                                    <a
                                        href="https://maps.app.goo.gl/8owYKwxDny938Qqu8"
                                        className='flex items-center space-x-2 hover:text-[#3B82F680] text-[#00000073]'
                                    >
                                        <MdLocationPin size={50}  />
                                        <p className='text-sm'>
                                        No. 648/A-3, 1st Floor, OM Chambers, Binnamangala, 1st Stage, Indiranagar Bangalore – 560038
                                        </p>
                                    </a>
                                    <div className="mt-2 text-[#00000073]">
                                        <div className="flex items-center space-x-2">
                                        <MdWifiCalling3 className='text-xl' />
                                        <a href="tel:+919391183220" className="text-sm hover:text-[#3B82F680]">9133309629</a>
                                        </div>
                                        <div className="flex items-center space-x-2 mt-1">
                                        <MdOutgoingMail className='text-xl' />
                                        <a href="mailto:esupport@Clearcover.in" className="text-sm hover:text-[#3B82F680]">support@ClearCover.in</a>
                                        </div>
                                    </div>
                                </div>
                                {/* {branch_contact.map((item, index)=>(
                                    <div key={index} className="shadow-lg p-6 rounded-lg bg-white transition-transform duration-300 ease-in-out transform hover:scale-105">
                                    <h2 className='text-xl font-semibold text-[#9ca3af] mb-0.5'>{item.state}</h2>
                                    <p className='text-[15px] font-semibold text-[#9ca3af] mb-2'>Branch Manager: {item.branch_manager}</p>
                                    <a
                                        href={`${item.location}`}
                                        className='flex items-center space-x-2 hover:text-[#3B82F680] text-[#00000073]'
                                    >
                                        <MdLocationPin size={50}  />
                                        <p className='text-sm'>
                                         {item.adress}
                                        </p>
                                    </a>
                                </div>))} */}
                            </div>
                        </div>                          
                    </div>

                     {/* ###### Second Column ###### */}
                <div className="flex justify-center h-[550px]">
                    <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                        {/* <h2 className="text-2xl font-bold text-center mb-6 text-[#61BC6D]">Reach Out for Personalized Assistance</h2> */}
                        <h2 className="text-2xl font-bold text-center mb-6 ">Have Questions? <span className='text-[#61BC6D]'> We're Ready to Assist</span></h2>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="name" className="font-semibold text-gray-700">Name</label>
                                <input
                                    name="name"
                                    value={contactForm.name}
                                    onChange={handleChange}
                                    type="text"
                                    id="name"
                                    className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#61BC6D] transition"
                                    placeholder="Enter your name"
                                    required
                                    />
                                    <h1 className='h-[10px] mt-[-10px]'>{errors.name && <span className="text-red-600 text-[10px]">{errors.name}</span>}</h1>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label htmlFor="email" className="font-semibold text-gray-700">Email</label>
                                    <input
                                        name="email"
                                        value={contactForm.email}
                                        onChange={handleChange}
                                        type="email"
                                        id="email"
                                        className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#61BC6D] transition"
                                        placeholder="Enter your email"
                                        required
                                    />
                                    <h1 className='h-[10px] mt-[-10px]'>{errors.email && <span className="text-red-600 text-[10px]">{errors.email}</span>}</h1>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label htmlFor="message" className="font-semibold text-gray-700">Message</label>
                                    <textarea
                                        name="message"
                                        value={contactForm.message}
                                        onChange={handleChange}
                                        id="message"
                                        rows="4"
                                        className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#61BC6D] transition"
                                        placeholder="Your message"
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="bg-[#61BC6D] text-white p-3 rounded-md font-bold hover:bg-[#4CAF50] transition"
                                >
                                    Send
                                </button>
                            </div>
                               {message && <div className="text-center text-green-600 font-semibold mt-1">{message}</div>}
                    </form>
                </div>
            </div>
               

            </div>


        </div>









    // <div className="py-14 flex flex-col gap-14 md:gap-20">
    //     <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center px-4 md:mt-8 ">
    //                <div className="flex flex-col  items-center gap-4 md:gap-6">
    //                     <h1 className='text-[22px] sm:text-5xl font-bold '>Contact us</h1>
    //                     <div className="flex-col gap-0.5 text-[8px] sm:text-lg lg:text-[14px] xl:text-[18px] tracking-wider text-[#161C2D]">
    //                         <p>With lots of unique blocks, you can easily build a page without </p>
    //                         <p>coding. Build your next consultancy website within few minutes.</p>
    //                     </div>
    //                 </div>

    //                 {/* <div className="flex flex-row items-center"> */}
    //                    <div className="grid grid-cols-1  lg:grid-cols-2 gap-14 lg:gap-32 justify-center items-center mt-14">
    //                         <form onSubmit={handleSubmit} action="">
    //                         <div className=" grid gris-cols-1 gap-2 min-w-[300px] bg-[#fff] max-w-[340px] rounded-lg shadow-xl p-4 ">
    //                                 <div className="grid grid-cols-1 gap-1">
    //                                     <label htmlFor="name" className='font-bold'>Name</label>
    //                                     <input name='name' value={contactForm.name} onChange={handleChange}
    //                                     type="text"  className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]' />
    //                                 </div>

    //                                 <div className="grid grid-cols-1 gap-1">
    //                                     <label htmlFor="name" className='font-bold'>Email</label>
    //                                     <input name='email' value={contactForm.email} onChange={handleChange}
    //                                      type="email" className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'  />
    //                                 </div>

    //                                 {/* <div className="grid grid-cols-1 gap-1">
    //                                     <label htmlFor="subject" className='font-bold'>Subject</label>
    //                                     <input name='subject' value={contactForm.subject} onChange={handleChange}
    //                                     type="text" className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'  />
    //                                 </div> */}

    //                                 <div className="grid grid-cols-1 gap-1">
    //                                     <label htmlFor="message" className='font-bold'>Message</label>
    //                                     <textarea name='message' value={contactForm.message} onChange={handleChange}
    //                                     cols={20} rows={2} type="text" className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'  />
    //                                 </div>

    //                                 <div className="flex flex-row justify-center items-center bg-[#61BC6D] rounded-md mt-4">
    //                                     <button type='submit' className='p-3 rounded-md text-[#ffff] font-bold'>Send</button>
    //                                 </div>
                                   
    //                         </div>
    //                         {message && <div className="text-center text-green-600 font-bold mb-4">{message}</div>}
    //                      </form>
                        
                              
    //                           <div className="flex flex-col justify-center gap-10">
    //                                     <div className="flex flex-col gap-2">
    //                                         <h1 className='text-xl font-bold'>Call us</h1>
    //                                         <div className="flex flex-col gap-1 tracking-widest justify-center ">
    //                                             <span><a href="tel:+917026371000">+91 70263 71000</a></span>
    //                                             <span><a href="tel:+919553871111">+91 95538 71111</a></span>
    //                                         </div>
    //                                     </div>

    //                                     <div className="flex flex-col gap-2">
    //                                         <h1 className='text-xl font-bold'>Email us</h1>
    //                                         <div className="flex flex-col gap-1 tracking-widest justify-center">
    //                                             {/* <span><a href="mailto:info@mdhinsurance.in">info@mdhinsurance.in</a></span>   */}
    //                                             <span><a href="mailto:esupport@Clearcover.in">support@ClearCover.in</a></span>

    //                                         </div>
    //                                     </div>

    //                                     <div className="flex flex-col gap-2">
    //                                         <h1 className='text-xl font-bold'>Visit us</h1>
    //                                         <a href="https://maps.app.goo.gl/mCc6VLZ26AqHxBJ98" className='hover:text-sky-500'>
    //                                            <div className="flex flex-col gap-1 tracking-widest justify-center">
    //                                             <span>1st floor, 2gethr @ ORR,</span>
    //                                             <span>TOWER-B, MANTRI COMMERCIO</span>
    //                                             <span>Outer Ring Rd, Bellandur, 560103</span>
    //                                            </div>
    //                                         </a>
    //                                     </div>
    //                           </div>
    //                 </div>
    //         </div>
    //     </div>

    // </div>
  )
}

export default Contact