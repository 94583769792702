import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'

function ProfessionalIndeminityInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Professional Indemnity Insurance </h1>
    </div>
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>What is Professional Indemnity Insurance?  </h1>
        <p>Professional Indemnity Insurance protects professionals against legal claims arising from errors, omissions, or negligence in their services. Doctors, lawyers, consultants, and architects must safeguard their reputations and finances.    </p>


        <h1 className=' text-lg md:text-xl font-bold'>Benefits of General Liability Insurance : </h1>

        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Legal Protection: </li>
                <p>Covers legal fees and settlements. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Reputation Management:</li>
                <p> Helps maintain professional reputation. </p>
            </ul>
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Financial Security: </li>
                <p>Protects against significant financial losses due to claims. </p>
            </ul>

        </div>

        <h1 className=' text-lg md:text-xl font-bold'>Key Benefits Include: </h1>
        <div className="flex flex-col gap-4">
            <ul className='flex flex-col gap-1'>

                <li className='text-md font-bold'>Error and Omission Coverage:</li>
                <p>Protection against claims of professional mistakes.  </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Negligence Coverage:</li>
                <p>  Insurance for claims arising from professional negligence. </p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Legal Costs:</li>
                <p>Covers legal defense costs.</p>
            </ul>

            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Settlement Costs:</li>
                <p> Compensation for settlements or court judgments. </p>
            </ul>
            <ul className='flex flex-col gap-1'>
                <li className='text-md font-bold'>Crisis Management:</li>
                <p> Support for managing reputational damage.</p>
            </ul>
        </div>
    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default ProfessionalIndeminityInsurance